import dayjs from 'dayjs';
export interface IAvatar {
    id: number;
    _id: string;    //av_id
    _sort: number;    //av_sort
    name: string;
    part: string;
    icon: string;
    color: string;
    type: string;
    date_start: string;
    date_expire: string;
    activate: boolean;
    thumbnail: IThumbnail[]
}
export interface IThumbnail {
    id: number;
    name: string;
    _sort: number;   //th_sort
    icon: string;
    url: string;
    fg: string;
    color: string;
}

export const DAvatar = (): IAvatar => {
    return {
        id: 0,
        _id: "",
        _sort: 0,
        name: "",
        part: "",
        icon: "",
        color: "",
        type: "normal",
        date_start: dayjs().format('YYYY-MM-DD'),
        date_expire: dayjs().format('YYYY-MM-DD'),
        activate: true,
        thumbnail: []
    }
}

export function DThumbnail(): IThumbnail {
    return {
        id: 0,
        name: "",
        _sort: 0,
        icon: "",
        url: "",
        fg: "",
        color: ""
    }
}


export function CompareAvatars(avatar1: IAvatar, avatar2: IAvatar): boolean {
    // Compare the simple properties of IAvatar
    return (avatar1.id === avatar2.id &&
        avatar1._id === avatar2._id &&
        avatar1._sort === avatar2._sort &&
        avatar1.name === avatar2.name &&
        avatar1.part === avatar2.part &&
        avatar1.icon === avatar2.icon &&
        avatar1.color === avatar2.color &&
        avatar1.type === avatar2.type &&
        avatar1.date_start === avatar2.date_start &&
        avatar1.date_expire === avatar2.date_expire &&
        avatar1.activate === avatar2.activate &&
        deepCompareThumbnails(avatar1.thumbnail, avatar2.thumbnail) // Deep compare the 'thumbnail' array
    );
    /* if (
         a.id !== b.id ||
         a._id !== b._id ||
         a._sort !== b._sort ||
         a.name !== b.name ||
         a.part !== b.part ||
         a.icon !== b.icon ||
         a.color !== b.color ||
         a.type !== b.type ||
         a.date_start !== b.date_start ||
         a.date_expire !== b.date_expire ||
         a.activate !== b.activate ||
         !deepCompareThumbnails(a.thumbnail, b.thumbnail) // Deep compare the 'thumbnail' array
     ) {
         return false;
     }
     // Compare the thumbnail arrays
      if (a.thumbnail.length !== b.thumbnail.length) {
         return false;
     }
 
     for (let i = 0; i < a.thumbnail.length; i++) {
         const thumbnailA: IThumbnail = a.thumbnail[i];
         const thumbnailB: IThumbnail = b.thumbnail[i];
 
         if (
             thumbnailA.id !== thumbnailB.id ||
             thumbnailA.name !== thumbnailB.name ||
             thumbnailA.icon !== thumbnailB.icon ||
             thumbnailA.url !== thumbnailB.url ||
             thumbnailA.fg !== thumbnailB.fg ||
             thumbnailA.color !== thumbnailB.color
         ) {
             return false;
         }
     }
  
     return true;*/
}

function deepCompareThumbnails(thumbnails1: IThumbnail[], thumbnails2: IThumbnail[]): boolean {
    if (thumbnails1.length !== thumbnails2.length) {
        return false;
    }
    for (let i = 0; i < thumbnails1.length; i++) {
        const thumbnailA: IThumbnail = thumbnails1[i];
        const thumbnailB: IThumbnail = thumbnails2[i];

        if (
            thumbnailA.id === thumbnailB.id &&
            thumbnailA.name === thumbnailB.name &&
            thumbnailA._sort === thumbnailB._sort &&
            thumbnailA.icon === thumbnailB.icon &&
            thumbnailA.url === thumbnailB.url &&
            thumbnailA.fg === thumbnailB.fg &&
            thumbnailA.color === thumbnailB.color
        ) {
            return true;
        }
    }
    return false;
}