import { HttpClient } from './httpClient';
import { IAvatar, IThumbnail } from '../Models/IAvatar.model';
import env from "./env";
import { AxiosRequestConfig } from "axios";

export interface IAvatarService {
    create: (avatar: Partial<IAvatar>, logo: any, url: any, fg: any, icon: any) => Promise<IAvatar>;
    updateContent: (id: string, avatar: Partial<IAvatar>) => Promise<IAvatar>;
    updateContentImages: (id: string, avatar: Partial<IAvatar>, logo: any, urls: any[], fgs: any[], icons: any[]) => Promise<IAvatar>;
    getContentAllPart: (part: string) => Promise<IAvatar[]>;
    getContent: (id: string) => Promise<IAvatar>;
    deleteContent: (id: string) => Promise<Boolean>;
    //uploads: (part: string, logo: File | null, thumb: (File | null)[], bg: (File | null)[], icon: (File | null)[]) => Promise<any>;
}

class AvatarService extends HttpClient implements IAvatarService {
    /* constructor() {
        super();
    } */
    public async create(avatar: Partial<IAvatar>) {
        try {
            const url = `${env.APP_API_HOST}/avatars/${avatar.part}`;
            const response = await this.post(url, avatar);
            if (!response.status) {   //ERR_CONNECTION_REFUSED
                throw new Error("Connection failed Please try again.");
            }
            if (!response.data) {
                throw new Error("ไม่สำเร็จ กรุณารอสักครู่ และลองใหม่อีกครั้งภายหลัง");
            }
            const data: any = response.data;
            if (data.status === "error") {
                throw new Error("ไม่สำเร็จ กรุณาลองใหม่อีกครั้ง");
            }
            return response.data as IAvatar;
        } catch (e) {
            throw new Error("ไม่สำเร็จ กรุณารอสักครู่ และลองใหม่อีกครั้งภายหลัง");
        }
    }
    /* public async create(avatar: Partial<IAvatar>, logo: any, urls: any[], fgs: any[], icons: any[]): Promise<IAvatar> {
        try {
            var formData = new FormData();
            Object.entries(avatar).forEach(([key, value]) => {
                if (key !== 'thumbnail') {
                    formData.append(key, String(value));
                }
            });
            formData.append(`thumbnail`, JSON.stringify(avatar.thumbnail));
            formData.append('logo', logo);
            for (var i = 0; i < icons.length; i++) {
                formData.append(`urls`, urls[i] ? urls[i] : null);
                formData.append(`fgs`, fgs[i] ? fgs[i] : null);
                formData.append(`icons`, icons[i] ? icons[i] : null);
            }
            const options: AxiosRequestConfig = {}
            options.headers = { 'Content-Type': 'multipart/form-data' }
            const url = `${env.APP_API_HOST}/avatars/${avatar.part}`;
            const response = await this.post(url, formData, options);


            // const url = `${env.APP_API_HOST}/avatars`;
            //const response = await this.post(url, avatar);
            if (!response.status) {   //ERR_CONNECTION_REFUSED
                throw new Error("Connection failed Please try again.");
            }
            if (!response.data) {
                throw new Error("ไม่พบข้อมูล");
            }
            const data: any = response.data;
            if (data.status === "error") {
                throw new Error("ไม่พบข้อมูล");
            }
            return response.data as IAvatar;
        } catch (e) {
            throw e;
        }
    } */

    public async updateContentImages(id: string, avatar: Partial<IAvatar>, logo: any, urls: any[], fgs: any[], icons: any[]): Promise<IAvatar> {
        var formData = new FormData();
        try {
            Object.entries(avatar).forEach(([key, value]) => {
                if (key !== 'thumbnail') {
                    formData.append(key, String(value));
                }
            });
            formData.append(`thumbnail`, JSON.stringify(avatar.thumbnail));
            formData.append('logo', logo);
            for (var i = 0; i < icons.length; i++) {
                if (urls[i] !== null) {
                    formData.append(`urls`, urls[i]);
                }
                if (fgs[i] !== null) {
                    formData.append(`fgs`, fgs[i]);
                }
                if (icons[i] !== null) {
                    formData.append(`icons`, icons[i]);
                }
            }

            const options: AxiosRequestConfig = {}
            options.headers = { 'Content-Type': 'multipart/form-data' }
            const url = `${env.APP_API_HOST}/avatars/${id}/${avatar.part}`;

            const response = await this.post(url, formData, options);
            if (!response.status) {   //ERR_CONNECTION_REFUSED
                throw new Error("Connection failed Please try again.");
            }
            if (!response.data) {
                throw new Error("ไม่พบข้อมูล");
            }
            const data: any = response.data;
            if (data.status === "error") {
                throw new Error("ไม่พบข้อมูล");
            }
            return response.data as IAvatar;
        } catch (e) {
            throw e;
        }
    }
    //--------------------------------------------------//
    //--------------------------------------------------//
    public async addIcon(id: number, part: string, thumb: any): Promise<IAvatar> {
        try {
            var formData = new FormData();
            formData.append('file', thumb);
            const options: AxiosRequestConfig = {}
            options.headers = { 'Content-Type': 'multipart/form-data' }
            const response = await this.post(`${env.APP_API_HOST}/avatars/${id}/${part}/icon`, formData, options);
            if (!response.status) {   //ERR_CONNECTION_REFUSED
                throw new Error("Connection failed Please try again.");
            }
            if (!response.data) {
                throw new Error("ไม่สำเร็จ กรุณารอสักครู่ และลองใหม่อีกครั้งภายหลัง");
            }
            const data: any = response.data;
            if (data.status === "error") {
                throw new Error("ไม่สำเร็จ ลองใหม่อีกครั้งภายหลัง");
            }
            return response.data as IAvatar;
        } catch (e) {
            throw new Error("ไม่สำเร็จ กรุณารอสักครู่ และลองใหม่อีกครั้งภายหลัง");
        }
    }

    public async addThumb(id: number, part: string, ithumb: Partial<IThumbnail>, url: (File | null), fg: (File | null), icon: (File | null)) {
        try {
            var formData = new FormData();
            if (icon !== null) {
                formData.append(`icon`, icon);
            }
            if (url !== null) {
                formData.append(`url`, url);
            }
            if (fg !== null) {
                formData.append(`fg`, fg);
            }
            Object.entries(ithumb).forEach(([key, value]) => {
                formData.append(key, String(value));
            });

            const options: AxiosRequestConfig = {}
            options.headers = { 'Content-Type': 'multipart/form-data' }
            const response = await this.post(`${env.APP_API_HOST}/avatars/${id}/${part}/thumbnail`, formData, options);
            if (!response.status) {   //ERR_CONNECTION_REFUSED
                throw new Error("Connection failed Please try again.");
            }
            if (!response.data) {
                throw new Error("ไม่สำเร็จ กรุณารอสักครู่ และลองใหม่อีกครั้งภายหลัง");
            }
            const data: any = response.data;
            if (data.status === "error") {
                throw new Error("ไม่สำเร็จ ลองใหม่อีกครั้งภายหลัง");
            }
            return response.data as IThumbnail;
        } catch (e) {
            throw new Error("ไม่สำเร็จ กรุณารอสักครู่ และลองใหม่อีกครั้งภายหลัง");
        }
    }

    public async removeThumb(id: Number, tids: string) {
        try {
            const url = `${env.APP_API_HOST}/avatars/${id}/thumbnails/${tids}`;
            const response = await this.delete(url);
            if (!response.status) {   //ERR_CONNECTION_REFUSED
                throw new Error("Connection failed Please try again.");
            }
            if (!response.data) {
                throw new Error("ไม่พบข้อมูล");
            }
            const data: any = response.data;
            if (data.status === "error") {
                throw new Error("ไม่สำเร็จ ลองใหม่อีกครั้ง");
            }
            return Boolean(response.data);
        } catch (e) {
            throw e;
        }
    }
    //--------------------------------------------------//
    //--------------------------------------------------//
    public async updateContent(id: string, avatar: Partial<IAvatar>): Promise<IAvatar> {
        try {
            const url = `${env.APP_API_HOST}/avatars/${id}`;
            const response = await this.patch(url, { ...avatar });
            if (response.status === undefined) {  //server failure
                throw new Error("ไม่สำเร็จ กรุณารอสักครู่ และลองใหม่อีกครั้งภายหลัง");
            }

            if (!response.data) {
                throw new Error("ไม่สำเร็จ ลองใหม่อีกครั้งภายหลัง");
            }
            const data: any = response.data;
            return data;
        } catch (e) {
            throw e;
        }
    }

    //หาเฉพาะส่วน
    public async getContentAllPart(part: string): Promise<IAvatar[]> {
        try {
            const url = `${env.APP_API_HOST}/avatars/part/${part}`;
            const response = await this.get(url);
            if (!response.status) {   //ERR_CONNECTION_REFUSED
                throw new Error("Connection failed Please try again.");
            }
            if (!response.data) {
                throw new Error("ไม่พบข้อมูล");
            }
            const data: any = response.data;
            if (data.status === "error") {
                throw new Error("ไม่พบข้อมูล");
            }
            return response.data as IAvatar[];
        } catch (e) {
            throw e;
        }
    }

    //หาเฉพาะชิ้น
    public async getContent(id: string): Promise<IAvatar> {
        try {
            const url = `${env.APP_API_HOST}/avatars/${id}`;
            const response = await this.get(url);
            if (!response.status) {   //ERR_CONNECTION_REFUSED
                throw new Error("Connection failed Please try again.");
            }
            if (!response.data) {
                throw new Error("ไม่พบข้อมูล");
            }
            const data: any = response.data;
            if (data.status === "error") {
                throw new Error("ไม่พบข้อมูล");
            }
            return response.data as IAvatar;
        } catch (e) {
            throw e;
        }
    }

    public async deleteContent(id: string): Promise<Boolean> {
        try {
            const url = `${env.APP_API_HOST}/avatars/${id}`;
            const response = await this.delete(url);
            if (!response.status) {   //ERR_CONNECTION_REFUSED
                throw new Error("Connection failed Please try again.");
            }
            if (!response.data) {
                throw new Error("ไม่พบข้อมูล");
            }
            const data: any = response.data;
            if (data.status === "error") {
                throw new Error("ไม่พบข้อมูล");
            }
            return Boolean(response.data);
        } catch (e) {
            throw e;
        }
    }

    //--------------------------------------------------//
    //--------------------------------------------------//
    /*  public async uploads(part: string, logo: File | null, thumb: (File | null)[], bg: (File | null)[], icon: (File | null)[]): Promise<any> {
         try {
             var formData = new FormData();
             if (logo !== null) {
                 formData.append(`logo`, logo);
             }
             thumb.forEach((file, index) => {
                 if (file !== null) {
                     formData.append(`files`, file);
                 }
             });
             bg.forEach((file, index) => {
                 if (file !== null) {
                     formData.append(`bgs`, file);
                 }
             });
             icon.forEach((file, index) => {
                 if (file !== null) {
                     formData.append(`icons`, file);
                 }
             });
 
             const options: AxiosRequestConfig = {}
             options.headers = { 'Content-Type': 'multipart/form-data' };
             const response = await this.post(`${env.APP_API_HOST}/avatars/uploads/${part}`, formData, options);
             if (!response.data) {
                 throw new Error("ไม่สำเร็จ กรุณารอสักครู่ และลองใหม่อีกครั้งภายหลัง");
             }
             const data: any = response.data;
             if (data.status === "error") {
                 throw new Error("ไม่สำเร็จ ลองใหม่อีกครั้งภายหลัง");
             }
             return response.data;
         } catch (e) {
             throw new Error("ไม่สำเร็จ กรุณารอสักครู่ และลองใหม่อีกครั้งภายหลัง");
         }
     } */
    //--------------------------------------------------//
    //--------------------------------------------------//
}

const service = new AvatarService();
export default service;