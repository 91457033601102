import React, { useState, useEffect, useImperativeHandle } from 'react'
import { DThumbnail, IThumbnail } from '../../../../Libs/Models/IAvatar.model';
import { ColorAvatar } from '../../../Common/ColorSelect';
import ColorService from '../../../../Libs/Services/Color.service';
import { IColor } from '../../../../Libs/Models/IColor.model';
import { Grid, Box } from '@mui/material';
import { IAvatarProps } from './detailType1';

import { Draggable, DragDropContext, Droppable } from 'react-beautiful-dnd';
import { useParams } from 'react-router-dom';

//--------------------------------------------------//
//--------------------------------------------------//
export const Type2 = React.forwardRef((props: IAvatarProps, ref) => {
    const { body } = useParams();
    const [colorList, SetColorList] = useState<IColor[]>([]);
    const [data, setData] = useState(props.data);
    const [removeData, setRemoveData] = useState<IThumbnail[]>([]);

    const [thumbnail, setThumbnail] = useState<IThumbnail[][]>([]);
    const [isCanNew, setIsCanNew] = useState(true);
    const [state, setState] = useState({ numPerList: 4, width: 300, height: 150 });
    const refs = React.useRef<any[]>([]);

    useEffect(() => {
        ColorService.getContentAll()
            .then(res => {
                let sort = res.reverse().sort((a: IColor, b: IColor) => a._sort - b._sort);
                if (body) {
                    sort = sort.filter(r => r.part.toLowerCase().indexOf(body) > -1)
                } else {

                }

                SetColorList(sort);
            })
            .catch((e) => { });

        if (props?.count < 2) {
            setState({ numPerList: 4, width: 300, height: 150 })
        } else {
            setState({ numPerList: 2, width: 550, height: 150 })
        }
        // sortData(JSON.parse(JSON.stringify(props.data)).thumbnail.map((item: IThumbnail) => ({ ...item })));
        //setData(JSON.parse(JSON.stringify(props.data)));
        //sortData(data.thumbnail.map((item) => ({ ...item })));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (props?.count < 2) {
            setState({ numPerList: 4, width: 300, height: 150 });
            sortData(data.thumbnail.map((item: IThumbnail) => ({ ...item })), 4);
        } else {
            setState({ numPerList: 2, width: 550, height: 150 })
            sortData(data.thumbnail.map((item: IThumbnail) => ({ ...item })), 2);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.count]);

    useEffect(() => {
        if (data.thumbnail.length > 0) {
            refs.current = Array.from({ length: data.thumbnail.length }, (_, index) => refs.current[index] || null);
            const ckeck = data.thumbnail.every(item => item.url !== '');
            setIsCanNew(ckeck);
        } else {
            setIsCanNew(true);
        }
        props.onUpdateHandler?.(data);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    //--------------------------------------------------//
    //--------------------------------------------------//
    useImperativeHandle(ref, () => {
        return {
            //ทำก่อน
            getData() {
                var _d_ = { ...data };
                var _color_ = refs.current?.map((thumb) => { return thumb.getData(); })
                _d_.thumbnail = _color_;
                _d_.icon = _d_.thumbnail[0].icon;  //แทนที่ icon หลัก ด้วย icon แรก
                return _d_;
            },
            /* getThumbs() {
                var _d_ = this.getData();
                return _d_.thumbnail;
            }, */
            getRemoveThumbs() { return removeData; },
            resetValue() { setRemoveData([]); }
        }
    });
    //--------------------------------------------------//
    //--------------------------------------------------//
    ///// GRAPHICS /////
    ///// อัพเดต THUMMBNAIL /////
    const onUpdateThumbHandler = async (index: number, value: IThumbnail) => {
        let newThumbs = data.thumbnail.map((item) => ({ ...item }));
        newThumbs[index] = value;
        sortData(newThumbs, state.numPerList);
    }

    const onNewThumbHandler = (index: number, value: IThumbnail) => {
        let newThumbs = data.thumbnail.map((item) => ({ ...item }));
        newThumbs = [...newThumbs, value];
        sortData(newThumbs, state.numPerList);
    }

    const onDeleteHandler = (value: IThumbnail) => {
        if (value.id > 0) {
            setRemoveData([...removeData, value]);  //ไว้ update server
        }
        const newThumbs = data.thumbnail.filter(item =>
            !(item.id === value.id
                && item.name === value.name
                && item.color === value.color
                && item.fg === value.fg
                && item.url === value.url
                && item.icon === value.icon
            )
        );
        sortData(newThumbs, state.numPerList)
    }

    //--------------------------------------------------//
    //--------------------------------------------------//
    const sortData = (d: IThumbnail[], numPerList: number) => {
        let tempData = [];
        for (let i = 0; i < d.length; i += numPerList) {
            tempData.push(d.slice(i, i + numPerList));
        }
        setThumbnail(tempData); //แบ่งเป็น IThumbnail[][]
        setData((prev) => ({ ...prev, icon: d[0]?.icon, thumbnail: d }));
    }

    const onDragEnd = (e: any) => {
        if (!e.destination) return;
        var tempData = [...thumbnail];
        const source_dropID = Number(e.source.droppableId.slice(5));            //"drop_xx"
        const destination_dropID = Number(e.destination.droppableId.slice(5));  //"drop_xx"

        var [source_data] = tempData[source_dropID].splice(e.source.index, 1);
        tempData[destination_dropID].splice(e.destination.index, 0, source_data);

        // return value
        var updateData = tempData.flat(1);
        sortData(updateData, state.numPerList);
    };
    //--------------------------------------------------//
    //--------------------------------------------------//
    const GridContainer = ({ items, subIndex }: any) => {
        return (
            <Grid container spacing={1} key={subIndex} wrap="nowrap" sx={{ py: 0.5, px: 0 }}>
                {items?.map((row: IThumbnail, index: number) => (
                    <Draggable key={index} index={index}
                        draggableId={`subIndex_${subIndex}_${index.toString()}`}
                    >{(provider) => (
                        <Grid item sx={{ width: state.width, height: state.height }}
                            key={subIndex * state.numPerList + index} ref={provider.innerRef}
                            {...provider.draggableProps} {...provider.dragHandleProps}>
                            <ColorAvatar index={subIndex * state.numPerList + index} key={subIndex} count={props.count}
                                options={colorList} data={row}
                                ref={el => refs.current[subIndex * state.numPerList + index] = el}
                                onUpdateHandler={onUpdateThumbHandler}
                                onDeleteHandler={onDeleteHandler}
                            />
                        </Grid>
                    )}
                    </Draggable>
                ))}
            </Grid>
        )
    }
    //--------------------------------------------------//
    //--------------------------------------------------//
    return (
        <Box sx={{ px: 1 }}>
            <DragDropContext onDragEnd={onDragEnd}>
                {thumbnail.map((item, subIndex) => (
                    <Droppable droppableId={`drop_${subIndex}`} direction='horizontal' key={subIndex}>
                        {(provided) => (
                            <div {...provided.droppableProps} ref={provided.innerRef}
                                style={{ height: 150 }} key={subIndex}
                            >
                                <GridContainer items={item} subIndex={subIndex} />
                            </div>
                        )}
                    </Droppable>
                ))}
            </DragDropContext >
            <Grid container spacing={1} sx={{ py: 0.5, px: 0 }}>
                {isCanNew &&
                    <ColorAvatar index={thumbnail.length} options={colorList} count={props.count}
                        onUpdateHandler={onNewThumbHandler}
                        data={DThumbnail()}
                        key={thumbnail.length}
                    />}
            </Grid>
        </Box>
    )
});
//--------------------------------------------------//
//--------------------------------------------------//