import React, { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography
} from "@mui/material";

import HTable, { IHeadTabel } from '../../Layout/HTable';
import { IOrder } from "../../../Libs/Models/IOrder.model";
import { day } from '../../../Libs/Extensions/Day.extension';
import { OrderType, stableSort } from '../../../Libs/Extensions/Number.extension';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

const headerCells: IHeadTabel[] = [
  { id: "id", label: "ORDER ID", align: "left", sort: true, width: 120 },
  { id: "date_ordered", label: "DATE", align: "left", sort: true, width: 100 },
  { id: null, label: "Name", align: "center", sort: true, width: 200 },
  { id: "shipping", label: "SHIP", align: "left", sort: true, width: 220 },
  { id: "total", label: "PRICE", align: "left", sort: true, width: 50 },
  { id: "status", label: "STATUS", align: "center", sort: true, width: 150 },
  { id: "tracking", label: "TRACKING NO.", align: "center", sort: true, width: 150 },
  { id: null, label: "", align: "center", sort: false },
];

const maxWidth = 1000

interface IProps {
  data?: IOrder[];
  isCanSort: boolean;
  isCanView: boolean;
}

export const TableList = (props: IProps) => {
  const navigate = useNavigate();
  const [orderBy, setOrderBy] = useState("index");
  const [orderType, setOrderType] = useState(OrderType.Asc);

  const [data, setData] = useState(props.data);

  useEffect(() => {
    setData(props.data);
  }, [props.data])


  const onClickView = (value: IOrder) => {
    //dispatch(Action.getOrderDetail(value));
    navigate(`/order/` + value._id);
  };
  //variant="dense"

  const sortHandler = (id: keyof IOrder) => {
    if (orderType === OrderType.Asc) {
      setOrderType(OrderType.Desc);
    } else {
      setOrderType(OrderType.Asc);
    }
    setOrderBy(id);
  }

  /* const onBeforeCapture = () => { };
  const onBeforeDragStart = () => { };
  const onDragStart = () => { };
  const onDragUpdate = () => { }; */
  const onDragEnd = (e: any) => {
    if (!e.destination) return;
    if (data) {
      let tempData = Array.from(data);
      let [source_data] = tempData.splice(e.source.index, 1);
      tempData.splice(e.destination.index, 0, source_data);
      setData(tempData);
    }
  };
  //--------------------------------------------------//
  //--------------------------------------------------//
  return (
    <div className="App mt-4">
      <DragDropContext onDragEnd={onDragEnd}>
        <Table size="small" sx={{ minWidth: maxWidth }} aria-label="customized table">
          <HTable cells={headerCells} sortHandler={sortHandler} isCanSort={props.isCanSort} />
          <Droppable droppableId="droppable-1">
            {(provider) => (
              <TableBody
                className="text-capitalize"
                ref={provider.innerRef}
                {...provider.droppableProps}
              >
                {stableSort(data, orderType, orderBy).map((row: any, index: number) => (
                  <Draggable
                    key={row._id}
                    draggableId={row._id}
                    index={index}
                  >
                    {(provider) => (
                      <TableRow key={row._id} {...provider.draggableProps} ref={provider.innerRef}>
                        <TableCell align="left" {...provider.dragHandleProps}>{row._id}</TableCell>
                        <TableCell align="left">{day(row.date_ordered)}</TableCell>
                        <TableCell align="center">{row.customer && row.customer.email && row.customer.email}</TableCell>
                        <TableCell align="left">{row.shipping}</TableCell>
                        <TableCell align="center">{row.total}</TableCell>
                        <TableCell align="center">{row.status}</TableCell>
                        <TableCell align="center">{row.tracking}</TableCell>
                        <TableCell align="left" colSpan={2}>
                          {props.isCanView &&
                            <Button variant="contained" size="small" sx={{ px: 3 }} onClick={() => { onClickView(row); }}>
                              <Typography variant="body1">VIEW</Typography>
                            </Button>
                          }
                        </TableCell>
                      </TableRow>
                    )}
                  </Draggable>
                ))}
                {provider.placeholder}
              </TableBody>
            )}
          </Droppable>
        </Table>
      </DragDropContext>
    </div>
  );

  //--------------------------------------------------//
  //--------------------------------------------------//
}