import React, { useState } from 'react'
import Box from '@mui/material/Box';
import { ThumbnailContainer, ImageRef } from '../../../Common/Thumbnail';
import { ResizeImage } from '../../../../Libs/Extensions/Image.extension';

interface IProps {
    onReset?: (index: number) => void;
    //onClear?: (index: number) => void;
    onUpdate?: (value: File | null, index: number) => void;
    src: string;
    part?: string;    //ต้องลบ**********
    label?: string;
    VW?: number;
    VH?: number;
    MAX_WIDTH?: number;
    MAX_HEIGHT?: number;
    index?: number;
}
export const PartDefault = React.forwardRef<ImageRef, IProps>((props, ref) => {
    const newRef = React.useRef<ImageRef>(null);
    const [img, setImg] = useState<File | null>(null);

    React.useImperativeHandle(ref, () => {
        return {
            getUrl() {
                return newRef.current ? newRef.current.getUrl() : '';
            },
            async getImage() {
                const url = await newRef.current?.getUrl();
                if (url && url.indexOf('blob') > -1) {
                    const response = await fetch(url);
                    const blobData = await response.blob();
                    const file = new File([blobData], 'tempimg.png', { type: 'image/png' });
                    return file;
                }
                return img;
            }
        }
    })
    //--------------------------------------------------//
    //--------------------------------------------------//
    const onUpdate = (value: File | null) => {
        if (value === null) {
            setImg(null);
            props.onUpdate?.(null, 0);
        } else {
            ResizeImage(value, props.MAX_WIDTH, props.MAX_HEIGHT,
                (resize: File) => {
                    setImg(resize);
                    props.onUpdate?.(resize, 0);
                });
        }
    }

    const onReset = () => {
        setImg(null);
        props.onReset?.(0);
    }

    //--------------------------------------------------//
    //--------------------------------------------------//
    return (
        <Box id="THUMBNAIL" className="flex-row">
            <ThumbnailContainer ref={newRef}
                onReset={onReset}
                onUpdate={(file: File | null) => onUpdate(file)}
                MAX_WIDTH={props.MAX_WIDTH && props.MAX_WIDTH} MAX_HEIGHT={props.MAX_HEIGHT && props.MAX_HEIGHT}
                VW={props.VW && props.VW} VH={props.VH && props.VH}
                src={props.src} label={props.label ? props.label : "GRAPHIC"}
            />
        </Box>
    )
});
//--------------------------------------------------//
//--------------------------------------------------//