import React, { useState } from 'react';
import { FormControl, MenuItem, Select, IconButton, Stack, Paper } from '@mui/material';
import { IColor } from '../../Libs/Models/IColor.model';
import { ThumbnailView, ImageRef } from './Thumbnail';

import DeleteIcon from '@mui/icons-material/Delete';
import env from '../../Libs/Services/env';
import { Box } from '@mui/system';
import { IThumbnail } from '../../Libs/Models/IAvatar.model';
import { useParams } from 'react-router-dom';
import { getIcon/* , getSize */ } from '../../Libs/Constants/size';
import MergeImages from './MergeImages';
import { ResizeImage } from '../../Libs/Extensions/Image.extension';


interface ColorSelectProps {
    value: string;
    options: IColor[];
    onChangeHandler?: (color: IColor | null) => void;
}

export const ColorSelect = (props: ColorSelectProps) => {
    const onClick = (c: IColor | null) => {
        if (c) {
            props.onChangeHandler && props.onChangeHandler(c);
        } else {
            props.onChangeHandler && props.onChangeHandler(null);
        }
    }
    //--------------------------------------------------//
    //--------------------------------------------------//
    return (
        <FormControl fullWidth variant="outlined" sx={{ width: 120 }} size="small">
            {props.options.length >= 0 && <Select id="demo-simple-select" inputProps={{ 'aria-label': 'Without label' }}
                value={props.value ? `${props.value.toString()}` : ''}
            >
                <MenuItem value="" onClick={() => onClick(null)}></MenuItem>
                {props.options.map((option) => (
                    <MenuItem key={option.id} value={option._id} onClick={() => onClick(option)}
                        style={{ display: 'flex', flexDirection: 'row' }}
                    >
                        <img src={`${env.APP_API_HOST}/${option.url}`} alt={option.name}
                            style={{ marginRight: 8, width: 25 }} />
                        {option.name}
                    </MenuItem>
                ))}
            </Select>}
        </FormControl >
    );
};

//--------------------------------------------------//
//--------------------------------------------------//
//--------------------------------------------------//
//--------------------------------------------------//
//--------------------------------------------------//
// สำหรับ hair ให้เลือกสีแล้ว -> ใส่ภาพ
export interface ColorAvatarProps {
    index: number;
    count: number;      //จำนวน thumbnail ที่จะ match เช่น ผมหน้าหลัง
    data: IThumbnail;
    options: IColor[];  //colorList
    onUpdateHandler?: (index: number, thumb: IThumbnail) => void;
    onUpdateLogoHandler?: (index: number, thumb: IThumbnail) => void;
    onDeleteHandler?: (thumb: IThumbnail) => void;
}

export const ColorAvatar = React.forwardRef((props: ColorAvatarProps, ref) => {
    const { body } = useParams();
    const iconS = getIcon(String(body));
    //const grapS = getSize(String(body));
    const [data, setData] = useState<IThumbnail>(props.data);   //DThumbnail()
    const logoRef = React.useRef<ImageRef>(null);

    React.useImperativeHandle(ref, () => {
        return {
            /* getUrl() {
                console.log(props.count, logoRef.current?.getUrl());
                if (props.count > 1) {//มี merge
                    console.log(logoRef.current?.getUrl());

                    return logoRef.current?.getUrl();
                } else {
                    return data.icon;
                }
            }, */
            getData() {
                if (props.count > 1) {  //มี merge
                    return { ...data, icon: logoRef.current?.getUrl() };
                }
                return { ...data };
            },
            async getImage() { /* return mergedImageUrl; */ }
        }
    });

    //--------------------------------------------------//
    //--------------------------------------------------//
    const onChangeColorHandler = (value: IColor | null) => {
        var _d_ = { ...data, color: value ? value._id : '' };
        setData(_d_);
        props.onUpdateHandler?.(props.index, _d_);
    }
    const onUpdateHandler = (value: File | null) => {
        onUpdateIconHandler('url', value);
    }
    const onUpdateFGHandler = (value: File | null) => {
        onUpdateIconHandler('fg', value);
    }
    //merge
    const onUpdateIconHandler = async (key: string, value: File | null) => {
        var url = data.url;
        var fg = data.fg;
        if (key === 'url') {
            url = value ? URL.createObjectURL(value) : "";
        } else {
            fg = value ? URL.createObjectURL(value) : "";
        }

        if (value) {
            if (props.count > 1) {  //มี merge
                var _d_: IThumbnail = { ...data, url: url, fg: fg, icon: logoRef.current?.getUrl() ? logoRef.current?.getUrl() : '' };
                setData(_d_);
                props.onUpdateHandler?.(props.index, _d_);
            } else {
                ResizeImage(value, iconS.w, iconS.h,
                    (resizedImage) => {
                        var icon = resizedImage ? URL.createObjectURL(resizedImage) : "";
                        var _d_ = { ...data, url: url, fg: fg, icon: icon };
                        setData(_d_);
                        props.onUpdateHandler?.(props.index, _d_);
                    })
            }
        } else {
            _d_ = { ...data, url: url, fg: fg };
            setData(_d_);
            props.onUpdateHandler?.(props.index, _d_);
        }
    }

    const onDeleteHandler = () => {
        props.onDeleteHandler?.(data);
    }

    //--------------------------------------------------//
    //--------------------------------------------------//
    return (
        <Stack direction="row" spacing={1} sx={{ pb: 2, pr: 2 }}
            className='flex-row flex-l'>
            <Box id="Color" sx={{ pt: 1 }}>
                <ColorSelect options={props.options}
                    onChangeHandler={onChangeColorHandler}
                    value={data?.color}
                />
            </Box>
            {data.color !== "" &&
                <Box className='flex-row flex-c'>
                    <OnView src={data.url} onUpdateHandler={onUpdateHandler} />
                    {props.count > 1 &&
                        <>
                            <Box sx={{ px: 1 }}>
                                <OnView src={data.fg}
                                    onUpdateHandler={onUpdateFGHandler}
                                />
                            </Box>
                            <Paper id='Preview' elevation={0} variant="outlined" className='flex-c-m'
                                sx={{ width: 100, height: 100, p: 1, mt: 1 }} >
                                <MergeImages ref={logoRef} data={[data.url, data.fg]}
                                    MAX_WIDTH={iconS.w} MAX_HEIGHT={iconS.h}
                                    //MAX_WIDTH={grapS.w} MAX_HEIGHT={grapS.h}
                                    w={100} h={100} />
                            </Paper>
                        </>
                    }
                    {(data.url !== "" || data.fg !== "") &&
                        <Paper className="flex-b" elevation={0} sx={{ px: 0, pb: 1 }}>
                            <IconButton aria-label="delete" size="medium" color="primary"
                                onClick={onDeleteHandler}>
                                <DeleteIcon />
                            </IconButton>
                        </Paper>
                    }
                </Box>
            }
        </Stack >
    );
});

//--------------------------------------------------//
//--------------------------------------------------//
//--------------------------------------------------//
//--------------------------------------------------//
//--------------------------------------------------//
interface IView {
    onUpdateHandler?: (value: File | null) => void;
    src: string | undefined;
}
const OnView = (props: IView) => {
    //const ref = useRef<HTMLImageElement>(null);
    return (
        <ThumbnailView //ref={ref}
            caption={
                <div className='flex-m flex-c'
                    style={{
                        width: 80, height: 80,
                        textAlign: 'center', lineHeight: 1
                    }}>
                    +
                </div>}
            VW={80} VH={80}
            //MAX_WIDTH={} MAX_HEIGHT={}
            onUpdate={props.onUpdateHandler}
            src={props.src}
        />
    )
}
//--------------------------------------------------//
//--------------------------------------------------//