import React, { useState, useEffect, useImperativeHandle } from 'react'
import { DThumbnail, IThumbnail, IAvatar } from '../../../../Libs/Models/IAvatar.model';
import { IAvatarProps } from './detailType1';
import { PartDefault } from './partDefault';
import { getSize } from '../../../../Libs/Constants/size';
import { Box, Typography, Paper } from '@mui/material';
import MergeImages from '../../../Common/MergeImages';
import { ImageRef } from '../../../Common/Thumbnail';

export const Type3 = React.forwardRef((props: IAvatarProps, ref) => {
    //const iconS = getIcon(props.part);
    const sizeS = getSize(props.part);
    const [data, setData] = useState<IAvatar>(JSON.parse(JSON.stringify(props.data)));
    //getImage
    const logoRef = React.useRef<ImageRef>(null);

    useEffect(() => {
        props.onUpdateHandler?.(data);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    useImperativeHandle(ref, () => {
        return {
            getData() {
                let logoUrl: any = logoRef.current?.getUrl();
                var thumbnail = [...data.thumbnail];
                thumbnail[0].icon = logoUrl;
                return { ...data, icon: logoUrl, thumbnail: thumbnail };
            },
            getThumbs() { return data.thumbnail; },
            getRemoveThumbs() { return []; },
            resetValue() { }
        }
    });
    //--------------------------------------------------//
    //--------------------------------------------------//
    ///// LOGO (THUMBNAIL) /////
    ///// อัพเดต LOGO /////
    /* const onUpdateLogo = (value: File | null) => {
        setData((prev) => ({ ...prev, icon: value ? URL.createObjectURL(value) : "" }));
    }
    const onResetLogo = () => {
        setData((prev) => ({ ...prev, icon: props.data.icon }));
    } */
    //--------------------------------------------------//
    //--------------------------------------------------//
    const onUpdateThumbFG = (value: File | null, index: number) => {
        onUpdateThumb(value, 0, 'fg');
    }
    const onResetThumbFG = () => {
        onResetThumb(0, 'fg');
    }
    const onUpdateThumbBG = (value: File | null, index: number) => {
        onUpdateThumb(value, 0, 'bg');
    }
    const onResetThumbBG = () => {
        onResetThumb(0, 'bg');
    }

    const onUpdateThumb = async (value: File | null, index: number, part: string) => {
        var thumbnail = [...data.thumbnail];
        if (thumbnail[index] === undefined) {
            thumbnail[index] = DThumbnail();
        }

        if (value) {  //value -> (url | fg), icon
            if (part === 'bg') {
                thumbnail[index].url = value ? URL.createObjectURL(value) : "";
            } else {
                thumbnail[index].fg = value ? URL.createObjectURL(value) : "";
            }
        } else {
            if (part === 'bg') {
                thumbnail[index].url = "";
            } else {
                thumbnail[index].fg = "";
            }
        }
        Merge(thumbnail[index], 0);
    }

    const onResetThumb = (index: number, part: string) => {
        var thumbnail = [...data.thumbnail];
        if (part === 'bg') {
            thumbnail[index].url = props.data.thumbnail[index]?.url;
        } else {
            thumbnail[index].fg = props.data.thumbnail[index]?.fg;
        }
        Merge(thumbnail[index], 0);
    }


    async function Merge(thumb: IThumbnail, index: number) {
        let logoUrl: any = logoRef.current?.getUrl();
        var thumbnail = [...data.thumbnail];
        thumbnail[index] = thumb;
        thumbnail[index].icon = logoUrl;
        setData((prev) => ({ ...prev, icon: logoUrl, thumbnail: thumbnail }));
    }
    //--------------------------------------------------//
    //--------------------------------------------------//
    return (
        <div>
            <div>
                <Typography variant="h6" >THUMNAIL</Typography>
                <Paper elevation={0} variant="outlined" sx={{ width: 220, height: 320, p: 1 }} >
                    <MergeImages w={200} h={300} MAX_WIDTH={sizeS.w} MAX_HEIGHT={sizeS.h} ref={logoRef}
                        data={[data.thumbnail[0]?.url, data.thumbnail[0]?.fg]} />
                </Paper>
            </div>
            <Box sx={{ py: 2 }}>
                <Typography variant="h6" >GRAPHIC</Typography>
                <div className='flex-row'>
                    <Box id="BACK" className="flex-row">
                        <PartDefault
                            index={0}
                            src={data?.thumbnail[0]?.url}
                            part={props.part}
                            onReset={onResetThumbBG}
                            onUpdate={onUpdateThumbBG}
                            VW={200} VH={300}
                            MAX_WIDTH={sizeS.w} MAX_HEIGHT={sizeS.h}
                            label='-- Back --' />
                    </Box>
                    <Box id="FRONT" sx={{ px: 2 }}>
                        <PartDefault
                            index={0}
                            src={data?.thumbnail[0]?.fg}
                            part={props.part}
                            onReset={onResetThumbFG}
                            onUpdate={onUpdateThumbFG}
                            VW={200} VH={300}
                            MAX_WIDTH={sizeS.w} MAX_HEIGHT={sizeS.h}
                            label='-- Front --' />
                    </Box>
                </div>
            </Box>
        </div>
    )
});
