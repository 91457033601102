import Swal from 'sweetalert2';

export const openDeleteConfirm = ({ title = 'Do you want to delete?', msg = '', action = null }: any) =>
    Swal.fire({
        title: `${title}`,
        html: '<FacebookIcon fontSize="large" />',
        icon: 'warning',
        confirmButtonText: 'Confirm',
        showDenyButton: true,
        denyButtonText: 'No',
        customClass: {
            actions: 'my-actions',
            //cancelButton: 'order-1 right-gap',
            confirmButton: 'order-1',
            denyButton: 'order-2',
        }
    }).then((result) => {
        if (result.isConfirmed) {
            //Swal.fire('Saved!', '', 'success')
            action?.();
        } else if (result.isDenied) {
            //Swal.fire('Changes are not saved', '', 'info')
        }
    });

export const openComplete = ({ title = 'Successfully', msg = '', action = null }: any) => {
    Swal.fire({
        html: '<FacebookIcon fontSize="large" />',
        title: `${title}`,
        // text: `${msg}`,
        //footer: '<a href="">Why do I have this issue?</a>',
        icon: "success",
    }).then(() => { action?.(); });
};

export const openError = ({ title = '', msg = '', action = null }: any) => {
    Swal.fire({
        html: '<FacebookIcon fontSize="large" />',
        title: `${title}`,
        // text: `${msg}`,
        //footer: '<a href="">Why do I have this issue?</a>',
        icon: "error",
    }).then(() => { action?.(); });
};