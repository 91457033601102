import { HInfo } from "./HInfo";
import { Outlet, useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { Waiting } from '../Common/Loading';
import { RootStore } from '../../Libs/Redux/index';
import { useSelector } from 'react-redux';

export default function LyInfo() {
  const location = useLocation();
  const [label, setLabel] = useState('');

  const [isWaiting, setIsWaiting] = useState(false);
  /* const [isComfirm, setIsComfirm] = useState(true);
  const [isError, setIsError] = useState(false); */
  const loader = useSelector((state: RootStore) => state.loader);

  useEffect(() => {
    if (location.pathname.indexOf('/order') > -1) {
      setLabel('ข้อมูลการสั่งสินค้า')
    } else if (location.pathname.indexOf('/customer') > -1) {
      setLabel('ข้อมูลลูกค้า')
    } else if (location.pathname.indexOf('/avatar') > -1) {
      setLabel('AVATAR')
    } else if (location.pathname.indexOf('/character') > -1) {
      setLabel('CHARACTER')
    } else if (location.pathname.indexOf('/productlist') > -1) {
      setLabel('PRODUCT LIST')
    } else if (location.pathname.indexOf('/staff') > -1) {
      setLabel('STAFF')
    }
  }, [location.pathname])

  useEffect(() => {
    setIsWaiting(loader.loading);
  }, [loader.loading])

  /* useEffect(() => {
    setIsError(loader.error.showing);
  }, [loader.error]) */

  //--------------------------------------------------//
  //--------------------------------------------------//
  return (
    <div className="flex-g">
      <Waiting open={isWaiting}></Waiting>
      {/* <Error /> */}
      <HInfo label={label} />
      <Outlet />
    </div>
  );
}

//--------------------------------------------------//
//--------------------------------------------------//
//--------------------------------------------------//
//--------------------------------------------------//