import React, { useState, useEffect, useImperativeHandle } from 'react'
import { IAvatar, IThumbnail, DThumbnail } from '../../../../Libs/Models/IAvatar.model';
import { useParams } from 'react-router-dom';
import { getSize, getIcon } from '../../../../Libs/Constants/size';
import { ResizeImage } from '../../../../Libs/Extensions/Image.extension';
import { PartDefault } from './partDefault';

export interface IAvatarProps {
    part: string;
    count: number;  //จำนวน thumnail ที่อัพได้(มีการ marge ไหม?)
    data: IAvatar;
    logoForThumb?: boolean;
    onUpdateHandler?: (value: IAvatar) => void;
}

export interface IAvatarRef {
    getData?: () => IAvatar;
    getThumbs?: () => IThumbnail[];
    getRemoveThumbs?: () => IThumbnail[];
    resetValue?: () => void;
}
//--------------------------------------------------//
//--------------------------------------------------//
export const Type1 = React.forwardRef((props: IAvatarProps, ref) => {
    const { body } = useParams();
    const iconS = getIcon(String(body));
    const sizeS = getSize(String(body));

    const [data, setData] = useState(props.data);

    useEffect(() => {
        props.onUpdateHandler?.(data);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    useImperativeHandle(ref, () => {
        return {
            getData() { return { ...data }; },
            getThumbs() { return data.thumbnail; },
            getRemoveThumbs() { return []; },
            resetValue() { }
        }
    });
    //--------------------------------------------------//
    //--------------------------------------------------//
    ///// LOGO (THUMBNAIL) /////
    ///// อัพเดต LOGO /////
    const onUpdateLogo = (value: File | null) => {
        setData((prev) => ({ ...prev, icon: value ? URL.createObjectURL(value) : "" }));
    }
    const onResetLogo = () => {
        setData((prev) => ({ ...prev, icon: props.data.icon }));
    }
    /* const onClearLogo = () => { props.onClearLogo?.(); } */
    //--------------------------------------------------//
    //--------------------------------------------------//
    ///// GRAPHICS /////
    ///// อัพเดต THUMMBNAIL /////
    const onUpdateThumb = async (value: File | null, index: number) => {
        var thumbnail = data.thumbnail;
        if (thumbnail[index] === undefined) {
            thumbnail[index] = DThumbnail();
        }

        if (value) {  //value -> (url | fg), icon
            // resize to icon
            ResizeImage(value, iconS.w, iconS.h,
                (resizedImage) => {
                    thumbnail[index].url = value ? URL.createObjectURL(value) : "";
                    thumbnail[index].icon = resizedImage ? URL.createObjectURL(resizedImage) : "";
                    if (data?.icon === "") { //logo
                        setData((prev) => ({
                            ...prev,
                            thumbnail: thumbnail,
                            icon: thumbnail[0].icon,
                        }));
                    } else {
                        setData((prev) => ({ ...prev, thumbnail: thumbnail }));
                    }
                })
        } else {
            thumbnail[index].url = "";
            thumbnail[index].icon = "";
            setData((prev) => ({ ...prev, thumbnail: thumbnail }));
        }
    }

    const onResetThumb = () => {
        setData((prev) => ({ ...prev, thumbnail: props.data.thumbnail.map((item) => ({ ...item })) }));
    }
    /* const onClearThumb = (index: number) => { props.onClearThumb?.(index); } */
    //--------------------------------------------------//
    //--------------------------------------------------//
    return (
        <div>
            <PartDefault index={0}
                onUpdate={onUpdateLogo} onReset={onResetLogo} //onClear={onClearLogo}
                label='THUMBNAIL'
                VW={iconS.w} VH={iconS.h}
                MAX_WIDTH={iconS.w} MAX_HEIGHT={iconS.h}
                src={data?.icon} />
            <PartDefault index={0}
                onUpdate={onUpdateThumb} onReset={onResetThumb} //onClear={onClearThumb}
                MAX_WIDTH={sizeS.w} MAX_HEIGHT={sizeS.h}
                src={data?.thumbnail[0]?.url} />
        </div>
    )
});
//--------------------------------------------------//
//--------------------------------------------------//
