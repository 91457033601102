import ActionType from "../@types/ActionType";
import IAction from "../@types/IAction";

export interface ILoaderState {
  loading: boolean;
  error: {
    msg: string;
    showing: boolean
  };
}

const initialState: ILoaderState = {
  loading: false,
  error: {
    msg: "",
    showing: false
  }
};

const reducer = (
  state: ILoaderState = initialState,
  action: IAction<any>
): ILoaderState => {
  var data = action.payload;
  switch (action.type) {
    case ActionType.LOADING_CHANGE:
      return {
        ...state,
        loading: data,
      };

    case ActionType.ERROR_SHOW:
      return {
        ...state,
        error: { msg: data, showing: true },
      };
    case ActionType.ERROR_CLOSE:
      return {
        ...state,
        error: { msg: '', showing: false },
      }

    default:
      return {
        ...state,
      };
  }
};

export default reducer;
