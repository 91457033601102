enum ActionType {
  NO_CHANGE,
  DESTROY,

  //Loading
  LOADING_CHANGE,
  LOADING_SPINNER_CHANGE,
  ERROR_SHOW,
  ERROR_CLOSE,

  LOG_IN,

  GET_ORDER_DETAIL,
  GET_CUSTOMER_DETAIL,
  GET_COLOR_DETAIL,
  GET_AVATAR_DETAIL,
  GET_COLLAB_DETAIL,
  GET_PRODUCT_DETAIL,
}

export default ActionType;
