import React, { useState, useEffect, useRef, useImperativeHandle } from "react";

import { Grid, Box } from '@mui/material';
import { IThumbnail } from "../../../Libs/Models/IAvatar.model";
import { ThumbnailView, ImageRef } from '../../Common/Thumbnail';

import { Draggable, DragDropContext, Droppable } from 'react-beautiful-dnd';
import DeleteIcon from '@mui/icons-material/Delete';

interface IThumbAdd {
    data: IThumbnail[];
    onUpdateThumb: (value: any) => void
    onDeleteThumb: (value: number) => void
    onAddThumb: (value: File | null, index: number) => void
}

export const ThumbAdd = React.forwardRef((props: IThumbAdd, ref) => {
    const refs = useRef<(ImageRef | null)[]>([]);
    const [data, setData] = useState<IThumbnail[][]>([]);
    const numPerList = 10;

    useEffect(() => {
        sortData(props.data);
    }, [props.data]);

    useEffect(() => {
        refs.current = Array.from({ length: data.length }, (_, index) => refs.current[index] || null);
    }, [data]);

    useImperativeHandle(ref, () => {
        return {
            getUrl() {
                const bValues = refs.current?.map((obj) => obj?.getUrl());
                return bValues;
            },
            async getImage() {
                const bValues = refs.current?.map(
                    async (obj) => {
                        return new Promise((resolve) => {
                            setTimeout(() => { resolve(obj?.getImage()); }, 100);
                        });
                    }
                );
                return await Promise.all(bValues);
            }
        }
    })

    //--------------------------------------------------//
    //--------------------------------------------------//
    const sortData = (d: IThumbnail[]) => {
        let tempData = [];
        for (let i = 0; i < d.length; i += numPerList) {
            tempData.push(d.slice(i, i + numPerList));
        }
        setData(tempData);
        //onUpdateThumb();
    }

    const onDragEnd = (e: any) => {
        if (!e.destination) return;
        var tempData = [...data];
        if (e.destination.droppableId === 'delete') {
            const source_dropID = Number(e.source.droppableId.slice(5));           //"drop_xx"
            const thumb = tempData[source_dropID][e.source.index];
            tempData[source_dropID].splice(e.source.index, 1);
            props.onDeleteThumb(thumb.id);
        } else {
            const source_dropID = Number(e.source.droppableId.slice(5));            //"drop_xx"
            const destination_dropID = Number(e.destination.droppableId.slice(5));  //"drop_xx"

            var [source_data] = tempData[source_dropID].splice(e.source.index, 1);
            tempData[destination_dropID].splice(e.destination.index, 0, source_data);
        }

        // return value
        var updateData = tempData.flat(1);
        props.onUpdateThumb?.(updateData);
        sortData(updateData);
    };
    //--------------------------------------------------//
    //--------------------------------------------------//
    const GridContainer = ({ items, subIndex }: any) => {
        return (
            <Grid container spacing={1} sx={{ py: 0.5 }} wrap="nowrap">
                {items.map((item: IThumbnail, index: number) => (
                    <Draggable key={index} index={index}
                        draggableId={`subIndex_${subIndex}_${index.toString()}`}
                    >{(provider) => (
                        <Grid item sx={{ width: 100, height: 100 }}
                            key={index} ref={provider.innerRef}
                            {...provider.draggableProps} {...provider.dragHandleProps}
                        >
                            <ThumbnailView
                                key={index}
                                ref={el => refs.current[index] = el}
                                id={index}
                                src={item?.url}
                                disabled={true}
                            />
                        </Grid>
                    )}
                    </Draggable>
                ))}
            </Grid>
        );
    };

    //--------------------------------------------------//    
    //--------------------------------------------------//
    return (
        <div>
            <DragDropContext onDragEnd={onDragEnd}> {
                data.map((item, subIndex) => (
                    <Droppable droppableId={`drop_${subIndex}`} direction='horizontal' key={subIndex}>
                        {(provided, snapshot) => (
                            <div {...provided.droppableProps} ref={provided.innerRef}
                                style={{ height: 110 }} key={subIndex}
                            >
                                <GridContainer items={item} subIndex={subIndex} />
                            </div>
                        )}
                    </Droppable>
                ))}
                <Grid container spacing={1} sx={{ py: 0.5 }}>
                    <Droppable droppableId={`delete`} direction='horizontal'>
                        {(provided) => (
                            <Grid item sx={{ width: 100, height: 100 }} {...provided.droppableProps}
                                ref={provided.innerRef} style={{ width: 100, height: 100 }}>
                                <Box className='flex-c-m'
                                    my={1}
                                    sx={{ borderWidth: 2, width: 92, height: 80, backgroundColor: 'rgba(0,0,0,0.15)' }}
                                >
                                    <DeleteIcon />
                                </Box>
                            </Grid>
                        )}
                    </Droppable>
                    <Grid item sx={{ width: 100, height: 100 }}>
                        <ThumbnailView //ref={newRef}
                            id={props.data ? props.data.length : 0} key={props.data ? props.data.length : 0}
                            caption={
                                <div className='flex-c-m' style={{ width: 60, height: 60 }}>+</div>
                            }
                            onUpdate={(value) => props.onAddThumb(value, props.data ? props.data.length : 0)}
                        />
                    </Grid>
                </Grid>
            </DragDropContext >
        </div >
    )
});
//--------------------------------------------------//
//--------------------------------------------------//