import { HttpClient } from './httpClient';
import { ICollab } from '../Models/ICollab.model';
import env from "./env";
import { AxiosRequestConfig } from "axios";
import { IThumbnail } from '../Models/IAvatar.model';

export interface ICollabService {
    create: (collab: Partial<ICollab>) => Promise<ICollab>;
    updateContent: (id: string, collab: Partial<ICollab>) => Promise<ICollab>;
    getContentAll: () => Promise<ICollab[]>;
    getContent: (id: string) => Promise<ICollab>;
    deleteContent: (id: string) => Promise<Boolean>;
    addIcon: (id: number, thumb: any) => Promise<ICollab>;
}

class CollabService extends HttpClient implements ICollabService {
    /* constructor() {
        super();
    } */
    public async create(collab: Partial<ICollab>) {
        try {
            const url = `${env.APP_API_HOST}/collabs`;
            const response = await this.post(url, collab);
            if (!response.status) {   //ERR_CONNECTION_REFUSED
                throw new Error("Connection failed Please try again.");
            }
            if (!response.data) {
                throw new Error("ไม่สำเร็จ กรุณารอสักครู่ และลองใหม่อีกครั้งภายหลัง");
            }
            const data: any = response.data;
            if (data.status === "error") {
                throw new Error("ไม่สำเร็จ กรุณาลองใหม่อีกครั้ง");
            }
            return response.data as ICollab;
        } catch (e) {
            throw new Error("ไม่สำเร็จ กรุณารอสักครู่ และลองใหม่อีกครั้งภายหลัง");
        }
    }

    public async updateContentImages(id: string, avatar: Partial<ICollab>, logo: any, urls: any[], icons: any[]): Promise<ICollab> {
        var formData = new FormData();
        try {
            Object.entries(avatar).forEach(([key, value]) => {
                if (key !== 'thumbnail') {
                    formData.append(key, String(value));
                }
            });
            formData.append(`thumbnail`, JSON.stringify(avatar.thumbnail));
            formData.append('logo', logo);
            for (var i = 0; i < icons.length; i++) {
                if (urls[i] !== null) {
                    formData.append(`urls`, urls[i]);
                }
                if (icons[i] !== null) {
                    formData.append(`icons`, icons[i]);
                }
                /* formData.append(`urls`, urls[i] ? urls[i] : null);
                formData.append(`icons`, icons[i] ? icons[i] : null); */
            }
            const options: AxiosRequestConfig = {}
            options.headers = { 'Content-Type': 'multipart/form-data' }
            const url = `${env.APP_API_HOST}/collabs/${id}/thumbnail`;

            //console.log(formData.get('urls'));
            // console.log(icons);

            const response = await this.post(url, formData, options);
            if (!response.status) {   //ERR_CONNECTION_REFUSED
                throw new Error("Connection failed Please try again.");
            }
            if (!response.data) {
                throw new Error("ไม่พบข้อมูล");
            }
            const data: any = response.data;
            if (data.status === "error") {
                throw new Error("ไม่พบข้อมูล");
            }
            return response.data as ICollab;
        } catch (e) {
            throw e;
        }
    }

    //--------------------------------------------------//
    //--------------------------------------------------//
    public async addIcon(id: number, thumb: any): Promise<ICollab> {
        try {
            var formData = new FormData();
            formData.append('file', thumb);
            const options: AxiosRequestConfig = {}
            options.headers = { 'Content-Type': 'multipart/form-data' }
            const response = await this.post(`${env.APP_API_HOST}/collabs/${id}/icon`, formData, options);
            if (!response.status) {   //ERR_CONNECTION_REFUSED
                throw new Error("Connection failed Please try again.");
            }
            if (!response.data) {
                throw new Error("ไม่สำเร็จ กรุณารอสักครู่ และลองใหม่อีกครั้งภายหลัง");
            }
            const data: any = response.data;
            if (data.status === "error") {
                throw new Error("ไม่สำเร็จ ลองใหม่อีกครั้งภายหลัง");
            }
            return response.data as ICollab;
        } catch (e) {
            throw new Error("ไม่สำเร็จ กรุณารอสักครู่ และลองใหม่อีกครั้งภายหลัง");
        }
    }

    public async addThumb(id: number, ithumb: Partial<IThumbnail>, thumb: (File | null), icon: (File | null)) {
        try {
            var formData = new FormData();
            if (icon !== null) {
                formData.append(`icon`, icon);
            }
            if (thumb !== null) {
                formData.append(`thumb`, thumb);
            }
            Object.entries(ithumb).forEach(([key, value]) => {
                formData.append(key, String(value));
            });

            const options: AxiosRequestConfig = {}
            options.headers = { 'Content-Type': 'multipart/form-data' }
            const response = await this.post(`${env.APP_API_HOST}/collabs/${id}/thumbnail`, formData, options);
            if (!response.status) {   //ERR_CONNECTION_REFUSED
                throw new Error("Connection failed Please try again.");
            }
            if (!response.data) {
                throw new Error("ไม่สำเร็จ กรุณารอสักครู่ และลองใหม่อีกครั้งภายหลัง");
            }
            const data: any = response.data;
            if (data.status === "error") {
                throw new Error("ไม่สำเร็จ ลองใหม่อีกครั้งภายหลัง");
            }
            return response.data as IThumbnail;
        } catch (e) {
            throw new Error("ไม่สำเร็จ กรุณารอสักครู่ และลองใหม่อีกครั้งภายหลัง");
        }
    }

    public async removeThumb(id: Number, tids: string) {
        try {
            const url = `${env.APP_API_HOST}/collabs/${id}/thumbnails/${tids}`;
            const response = await this.delete(url);
            if (!response.status) {   //ERR_CONNECTION_REFUSED
                throw new Error("Connection failed Please try again.");
            }
            if (!response.data) {
                throw new Error("ไม่พบข้อมูล");
            }
            const data: any = response.data;
            if (data.status === "error") {
                throw new Error("ไม่สำเร็จ ลองใหม่อีกครั้ง");
            }
            return Boolean(response.data);
        } catch (e) {
            throw e;
        }
    }
    //--------------------------------------------------//
    //--------------------------------------------------//
    public async updateContent(id: string, collab: Partial<ICollab>): Promise<ICollab> {
        try {
            const url = `${env.APP_API_HOST}/collabs/${id}`;
            const response = await this.patch(url, collab);
            if (!response.status) {   //ERR_CONNECTION_REFUSED
                throw new Error("Connection failed Please try again.");
            }
            if (response.status === undefined) {  //server failure
                throw new Error("ไม่สำเร็จ กรุณารอสักครู่ และลองใหม่อีกครั้งภายหลัง");
            }

            if (!response.data) {
                throw new Error("ไม่สำเร็จ ลองใหม่อีกครั้งภายหลัง");
            }
            const data: ICollab = response.data as ICollab;
            return data;
        } catch (e) {
            throw e;
        }
    }

    public async getContentAll(): Promise<ICollab[]> {
        try {
            const url = `${env.APP_API_HOST}/collabs`;
            const response = await this.get(url);
            if (!response.status) {   //ERR_CONNECTION_REFUSED
                throw new Error("Connection failed Please try again.");
            }
            if (!response.data) {
                throw new Error("ไม่พบข้อมูล");
            }
            const data: any = response.data;
            if (data.status === "error") {
                throw new Error("ไม่พบข้อมูล");
            }
            return response.data as ICollab[];
        } catch (e) {
            throw e;
        }
    }

    public async getContent(id: string) {
        try {
            const url = `${env.APP_API_HOST}/collabs/${id}`;
            const response = await this.get(url);
            if (!response.status) {   //ERR_CONNECTION_REFUSED
                throw new Error("Connection failed Please try again.");
            }
            if (!response.data) {
                throw new Error("ไม่พบข้อมูล");
            }
            const data: any = response.data;
            if (data.status === "error") {
                throw new Error("ไม่พบข้อมูล");
            }
            return response.data as ICollab;
        } catch (e) {
            throw e;
        }
    }

    public async deleteContent(id: string): Promise<Boolean> {
        try {
            const url = `${env.APP_API_HOST}/collabs/${id}`;
            const response = await this.delete(url);
            if (!response.status) {   //ERR_CONNECTION_REFUSED
                throw new Error("Connection failed Please try again.");
            }
            if (!response.data) {
                throw new Error("ไม่พบข้อมูล");
            }
            const data: any = response.data;
            if (data.status === "error") {
                throw new Error("ไม่สำเร็จ ลองใหม่อีกครั้ง");
            }
            return Boolean(response.data);
        } catch (e) {
            throw e;
        }
    }
}

const service = new CollabService();
export default service;