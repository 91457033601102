import React, { useState, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Button, Typography, IconButton, Stack, Tooltip } from '@mui/material';
import { TableContainer, Paper, Table, Box, TableBody, TableRow, TableCell, TableFooter, Modal } from '@mui/material';
import HTable from '../../Layout/HTable';
import { IHeadTabel } from '../../Layout/HTable';
import { IOrderProduct, IOrder, DOrder } from '../../../Libs/Models/IOrder.model';
import { day } from '../../../Libs/Extensions/Day.extension';
import OrderService from '../../../Libs/Services/Order.service';
import { OrderStatus } from '../../../Libs/Constants/enum'
import { TStatus, TypeIC, Search } from '../../Common';
import { ProductView } from './';
import env from '../../../Libs/Services/env';
import ColorService from '../../../Libs/Services/Color.service';
import { IColor } from '../../../Libs/Models/IColor.model';
import { AddressBox, IAddressRef } from '../../Common/TStatus';
//import CustomerService from '../../../Libs/Services/Customer.service';
import Divider from '@mui/material/Divider';
import { primary } from '../../../Styles/Theme';
import { BUpdate } from '../../Common/BUpdate';
import { HName } from '../../Common/HName';
import { openComplete, openDeleteConfirm, openError } from '../../Common/Dialog';
import DeleteIcon from '@mui/icons-material/Delete';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ErrorIcon from '@mui/icons-material/Error';

const headerCells: IHeadTabel[] = [
    { id: "id", label: "", align: "left", sort: false, width: 10 },
    { id: null, label: "PRODUCT", align: "left", sort: false, width: 50 },
    { id: "name", label: "", align: "left", sort: false, width: 200 },
    { id: "type", label: "TYPE", align: "left", sort: false, width: 60 },
    { id: "size", label: "SIZE", align: "left", sort: false, width: 60 },
    { id: "color", label: "COLOR", align: "center", sort: false, width: 60 },
    { id: "quantity", label: "QUANTITY", align: "center", sort: false, width: 100 },
    { id: "price", label: "PRICE/UNIT", align: "right", sort: false, width: 80 },
    { id: "total", label: "TOTAL", align: "right", sort: false, width: 80 },
    { id: null, label: "", align: "left", sort: false },
];

const maxWidth = 860

export function OrderDetail() {
    const { id } = useParams();
    const navigate = useNavigate();
    const ref = useRef<IAddressRef>(null);
    const [data, setData] = useState<IOrder>(DOrder());
    //const [address, setAddress] = useState<IAddress[]>();
    const [isOpenView, setIsOpenView] = useState(false);
    const [productView, setProductView] = useState<IOrderProduct>();
    const [color, setColor] = useState<IColor[]>();
    const [selectedAddress, setSelectedAddress] = useState(0);
    const [shipping, setShipping] = useState<string>("");
    const [tracking, setTracking] = useState<string>("");
    const [isCanUpdate, setIsCanUpdate] = useState(false);
    const [isOver, setIsOver] = useState(false);
    const [disShip, setDisShip] = useState(false);
    const [disTracking, setDisTracking] = useState(false);

    React.useEffect(() => {
        ColorService.getContentAll().then(res => {
            setColor(res);
        })
    }, [])
    React.useEffect(() => {
        if (id) {
            OrderService.getContent(id).then(res => {
                setData(JSON.parse(JSON.stringify(res)));
                setShipping(res.shipping);
                setTracking(res.tracking);

                ref.current?.setValue(res.shipping);

                if (res.shipping.trim().toLocaleLowerCase() === res.customer.address01.trim().toLocaleLowerCase()) {
                    setSelectedAddress(1)
                } else if (res.shipping.trim().toLocaleLowerCase() === res.customer.address02.trim().toLocaleLowerCase()) {
                    setSelectedAddress(2)
                } else if (res.shipping.trim().toLocaleLowerCase() === res.customer.address03.trim().toLocaleLowerCase()) {
                    setSelectedAddress(3)
                } else if (res.shipping.trim().toLocaleLowerCase() === res.customer.address04.trim().toLocaleLowerCase()) {
                    setSelectedAddress(4)
                }
                //setSelectedAddress(res.shipping);
            }).catch((e) => { openError({ title: `${e.message}` }) });
        }
    }, [id])

    React.useEffect(() => {
        const _isCanupdate_ = !(data.status.toLowerCase() === OrderStatus.PAYMENT_FAILED ||
            data.status.toLowerCase() === OrderStatus.CANCEL ||
            data.status.toLowerCase() === OrderStatus.DELIVERY_FAILED ||
            data.status.toLowerCase() === OrderStatus.FAILED ||
            data.status.toLowerCase() === OrderStatus.SUCCESSFUL)

        setIsCanUpdate(_isCanupdate_)
        setIsOver(!data.product.every(pd => pd.over === 0));

        if (data.status !== "") {
            setDisShip(_isCanupdate_ && data.shipping.trim().length === 0);
            setDisTracking(_isCanupdate_ && data.tracking.trim().length === 0 && (data.status === OrderStatus.PAYMENT_SUCCESS));
        }
    }, [data])

    const getColor = (c: String): string => {
        var res = color?.find(e => e._id === c);
        return res ? res.url : "";
    }
    //--------------------------------------------------//
    //--------------------------------------------------//
    const onClickViewImg = (r: IOrderProduct) => {
        setIsOpenView(true);
        setProductView(r);
    }
    const handleClose = () => {
        setIsOpenView(false);
    }


    const onChangeTackingHandler = (value: string) => {
        //setData((pre) => ({ ...pre, tracking: value }));
        setTracking(value.trim().length > 0 ? value : data.tracking);
    }
    //--------------------------------------------------//
    //--------------------------------------------------//
    const onClickCancel = () => {
        //Add modal Cancel *******************--------------------->
        //Add modal Cancel *******************--------------------->
        //Add modal Cancel *******************--------------------->
        var status = "";
        var isRetureProduct = false;

        switch (data.status) {
            case 'payment waiting': status = OrderStatus.PAYMENT_FAILED; isRetureProduct = true; break;
            case 'payment success': status = OrderStatus.CANCEL; break;
            case 'delivery waiting': status = OrderStatus.DELIVERY_FAILED; break;
            case 'delivery': status = OrderStatus.CANCEL; break;
            default: status = OrderStatus.FAILED; break;
        }

        OrderService.updateContent(data._id, { status: status })
            .then((res) => {
                setData(res);
                if (isRetureProduct) {
                    data.product.forEach(pd => {
                        onAddItem(pd.id, -pd.quantity);
                    })
                }
            })
            .catch(e => { openError({ title: `${e.message}` }) });
    }

    const onClickPaid = () => {
        var status = "";
        switch (data.status) {
            case 'payment waiting': status = OrderStatus.PAYMENT_SUCCESS; break;
            case 'payment success': status = OrderStatus.DELIVERY_WAITING; break;
            case 'delivery waiting': status = OrderStatus.DELIVERY; break;
            case 'delivery': status = OrderStatus.SUCCESSFUL; break;
            default: status = OrderStatus.SUCCESSFUL; break;
        }
        OrderService.updateContent(data._id, { status: status })
            .then((res) => { setData(res) })
            .catch(e => { openError({ title: `${e.message}` }) });
    }

    function onComfirm() {
        openDeleteConfirm({ title: 'Are you sure?', action: () => onClickPaid() });
    }

    //--------------------------------------------------//
    //--------------------------------------------------//
    const BStatus = ({ status }: any) => {
        var label = "";
        var cancel = "";
        var disabled = data.shipping.trim().length === 0;
        switch (status) {
            case "payment waiting": label = "payment success"; cancel = "payment failed"; break;
            case "payment success": label = "delivery waiting"; cancel = "cancel"; disabled = disabled || data.tracking.trim().length === 0; break;

            case "delivery waiting": label = "delivery"; cancel = "delivery failed"; break;
            case "delivery": label = "successful"; cancel = "cancel"; break;
        }
        return (
            <div className='dis-flex flex-row' style={{ paddingLeft: 340 }}>
                <Button variant="contained" sx={{ width: 110 }} color='error'
                    onClick={() => { openDeleteConfirm({ title: 'Are you sure?', action: () => onClickCancel() }) }}>
                    <Typography variant="body1">{cancel}</Typography>
                </Button>
                <Box sx={{ px: 1 }}></Box>
                <Button variant="contained" sx={{ width: 110 }} color='secondary'
                    onClick={onComfirm} disabled={disabled}>
                    <Typography variant="body1">{label}</Typography>
                    {isOver && <ErrorIcon color="error" sx={{ position: 'absolute', top: -10, right: -10 }} />}
                </Button>
            </div>
        )
    }

    //--------------------------------------------------//
    const onChangeAddress = (id: number, value: string) => {
        setShipping(value.trim().length > 0 ? value : data.shipping);
    }

    const handleAddressSelect = (address: String, index: number) => {
        setSelectedAddress(index);
        ref.current?.setValue(address.toString());
        setShipping(address.toString());
    };

    const onCancel = () => {
        navigate(-1);
    }

    const onConfirm = () => {
        if (id) {
            OrderService.updateContent(data._id, { ...data, shipping: shipping, tracking: tracking })
                .then((res) => { openComplete({ action: () => setData(res) }); })
                .catch(e => { openError({ title: `${e.message}` }) });
        }
    };

    const onDelete = async () => {
        //<<--back
        await OrderService.deleteContent(data._id)
            .then(() => { openComplete({}); })
            .catch(e => { openError({ title: `${e.message}` }) });
        navigate(-1);
    }

    const onDeleteItem = async (id: number) => {
        await OrderService.deleteContentItem(data._id, id)
            .then((res) => { openComplete({ action: () => setData(res) }); })
            .catch(e => { openError({ title: `${e.message}` }) });
    }

    const onAddItem = async (id: number, quantity: number) => {
        await OrderService.addContentItem(data._id, id, quantity)
            .then((res) => { openComplete({ action: () => setData(res) }); })
            .catch(e => { openError({ title: `${e.message}` }) });
    }

    const BAddress = ({ address, index, isClick }: any) => {
        if (address.trim().length <= 0) return <></>;
        return (
            <Button disabled={!isCanUpdate}
                variant={isClick ? "contained" : "outlined"}
                color={isClick ? 'primary' : 'inherit'}
                onClick={() => handleAddressSelect(address, index)}
                style={{ margin: '5px', width: 200, height: 80 }}
            >{address}
            </Button>
        )
    }

    //--------------------------------------------------//
    //--------------------------------------------------//
    return (
        <div className="flex-g">
            <Modal
                open={isOpenView}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <ProductView data={productView} />
            </Modal>
            <Box id="DETAIL" sx={{ px: 2 }} >
                <HName label={""} isCanDelete={data._id !== ""} onDelete={onDelete} />
                <Divider color={primary} />
            </Box>
            <Box id="DETAIL" sx={{ px: 2, py: 2 }} >
                <div className="dis-flex flex-row flex-m" >
                    <TStatus title={'Order ID'} msg={data && data._id}></TStatus>
                    <TStatus title={'Status'} msg={data && data.status.toString().toUpperCase()}></TStatus>
                </div>
                <div className="dis-flex flex-row flex-m">
                    <TStatus title={'Name'} msg={data && data.customer.email}></TStatus>
                    <Stack direction={'row'} sx={{ alignItems: 'center' }} spacing={-1.5}>
                        <Search type="fill" lable="Tracking No." value={data && data.tracking} hStyle={{ width: 100 }}
                            disabled={!isCanUpdate} placeholder={data.tracking}
                            onChangeHandler={onChangeTackingHandler} />
                        {disTracking && <ErrorIcon color="error" />}
                    </Stack>
                </div>
                <div className="dis-flex flex-row flex-m">
                    <TStatus title={'Order Date'} msg={day(data && data.date_ordered)}></TStatus>
                    <TStatus title={'Update Date'} msg={day(data && data.date_updated)}></TStatus>
                </div>
                <Stack direction={'row'} spacing={1}>
                    <AddressBox id={0} label={'Ship'} onChange={onChangeAddress}
                        dValue={data.shipping} ref={ref} disabled={!isCanUpdate}
                    />
                    {disShip && <ErrorIcon color="error" />}
                </Stack>
                <Box className="dis-flex flex-row" sx={{ py: 3 }}>
                    <Typography variant="h6" sx={{ width: 95 }}>{"Address"}</Typography>
                    <BAddress address={data.customer.address01} index={1} isClick={selectedAddress === 1} />
                    <BAddress address={data.customer.address02} index={2} isClick={selectedAddress === 2} />
                    <BAddress address={data.customer.address03} index={3} isClick={selectedAddress === 3} />
                    <BAddress address={data.customer.address04} index={4} isClick={selectedAddress === 4} />
                </Box>
            </Box>
            <Box id="TABEL" sx={{ px: 1, py: 3 }} >
                <TableContainer component={Paper} elevation={0}>
                    <Table size="small" sx={{ minWidth: maxWidth }} aria-label="customized table">
                        <HTable cells={headerCells} />
                        <TableBody>
                            {data.product.map((row: IOrderProduct, index) => (
                                <TableRow key={index}>
                                    <TableCell align="left">{index + 1}</TableCell>
                                    <TableCell align="left">
                                        <Paper className='flex-c-m' elevation={1} sx={{ backgroundColor: 'gray', p: 0.5 }}>
                                            <img src={`${env.APP_API_HOST}/${row.icon}`} alt="" width={'auto'} height={40} />
                                        </Paper>
                                    </TableCell>
                                    <TableCell align="left">{row.name.toUpperCase()}</TableCell>
                                    <TableCell align="center" >
                                        <TypeIC value={row.type} disabled={true} selected={true} size={40} />
                                    </TableCell>
                                    <TableCell align="center">{row.size}</TableCell>
                                    <TableCell align="center"><Paper className='flex-c-m' elevation={1} sx={{ width: 40 }} >
                                        <img src={`${env.APP_API_HOST}/${getColor(row.color)}`} alt="Color"
                                            style={{ width: 40, height: 'auto' }}
                                        />
                                    </Paper>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Stack direction={'row'} spacing={0.2}
                                            alignItems={'center'} justifyContent={'center'}>
                                            <div> {row.quantity} </div>
                                            {row.over < 0 &&
                                                <Tooltip placement="top"
                                                    title={`Order exceeds available quantity by ${Math.abs(row.over)}`}>
                                                    <ErrorIcon color="error" />
                                                </Tooltip>
                                            }
                                        </Stack>
                                    </TableCell>
                                    <TableCell align="right">{row.price}</TableCell>
                                    <TableCell align="right">{row.price * row.quantity}</TableCell>
                                    <TableCell align="left" colSpan={2} className='flex-m-c'>
                                        <Button variant="contained" size="small" sx={{ px: 2 }} onClick={() => { onClickViewImg(row); }}>
                                            <Typography variant="body1">VIEW IMAGE</Typography>
                                        </Button>
                                        {data.status === 'payment waiting' &&
                                            <>
                                                <IconButton sx={{ ml: 3 }}
                                                    onClick={() => onAddItem(row.id, -1)} >
                                                    <RemoveCircleIcon sx={{ color: '#E14745' }} />
                                                </IconButton>
                                                <IconButton sx={{ mx: 0 }}
                                                    onClick={() => onAddItem(row.id, 1)} >
                                                    <AddCircleIcon sx={{ color: '#42a840' }} />
                                                </IconButton>
                                                <IconButton
                                                    onClick={
                                                        () => openDeleteConfirm({ title: 'Do you want to delete this order?', action: () => onDeleteItem(row.id) })
                                                    }>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </>
                                        }
                                    </TableCell>
                                </TableRow>

                            ))}
                        </TableBody>
                        <TableFooter>
                            <TableRow sx={{ height: 20 }}></TableRow>
                            <TableRow>
                                <TableCell align="right" colSpan={headerCells.length - 3}>
                                    <Typography variant='h6' color="primary">TOTAL</Typography>
                                </TableCell>
                                <TableCell align="right" colSpan={2}>{ccyFormat(data.total)}</TableCell>
                                <TableCell align="right" colSpan={2}></TableCell>
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </Box>
            <Box className="dis-flex flex-row flex-m" sx={{ px: 2 }}>
                <BUpdate
                    onCancel={onCancel} onComfirm={onConfirm}
                    nLabel={"UPDATE"}
                    disabled={!isCanUpdate}
                />
                {isCanUpdate && <BStatus status={data.status.toLowerCase()} />}
            </Box>
        </div >
    )
}

function ccyFormat(num: number) {
    return `${num.toFixed(2)}`;
}

