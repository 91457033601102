import React, { useState } from 'react'
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import {
  Button,
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography
} from "@mui/material";

import HTable, { IHeadTabel } from '../../Layout/HTable';
import { OrderType, stableSort } from '../../../Libs/Extensions/Number.extension';
import Action from '../../../Libs/Redux/Actions/Action.action';
import { ICollab } from '../../../Libs/Models/ICollab.model';
import env from '../../../Libs/Services/env';
import { TypeIC } from '../../Common/TypeEvent';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { BUpdate } from '../../Common/BUpdate';
import CollabService from '../../../Libs/Services/Collab.service';

const headerCells: IHeadTabel[] = [
  { id: null, label: "", align: "left", sort: true, width: 30 },
  { id: null, label: "THUMBNAIL", align: "left", sort: false, width: 50 }, //thumbnail(image)
  { id: "name", label: "NAME", align: "left", sort: true, width: 150 },
  { id: null, label: "GRAPHIC", align: "center", sort: false, width: 50 },
  { id: null, label: "PRODUCT", align: "center", sort: true, width: 300 },
  { id: "type", label: "TYPE", align: "center", sort: true, width: 100 },
  { id: "activate", label: "ACTIVATE", align: "center", sort: true, width: 50 },
  { id: "date_expire", label: "EXPIRETION DATE", align: "center", sort: true, width: 180 },
  { id: null, label: "", align: "center", sort: false }, //column --> end
];

const maxWidth = 800

interface IProps {
  data: ICollab[];
  isCanSort: boolean;
  isCanView: boolean;
}

export const TableList = (props: IProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [orderType, setOrderType] = useState(OrderType.Asc);

  const [data, setData] = useState<ICollab[]>([]);
  const [temp_data, setTempData] = useState<ICollab[]>([]);
  const [isUpdated, setIsUpdated] = useState<boolean>(false);

  React.useEffect(() => {
    let tempData1 = stableSort(JSON.parse(JSON.stringify(props.data)), orderType, '_sort');
    let tempData2 = stableSort(JSON.parse(JSON.stringify(props.data)), orderType, '_sort');
    setTempData(tempData1);
    setData(tempData2);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data])
  //--------------------------------------------------//
  //--------------------------------------------------//
  const onClickView = (value: ICollab) => {
    dispatch(Action.getColorDetail(value));
    navigate(`/character/` + value._id);
  };

  const sortHandler = (id: keyof ICollab) => {
    let tempData = Array.from(data);
    if (orderType === OrderType.Asc) {
      setOrderType(OrderType.Desc);
      tempData = stableSort(tempData, OrderType.Desc, id);
    } else {
      setOrderType(OrderType.Asc);
      tempData = stableSort(tempData, OrderType.Asc, id);
    }
    setData(tempData);
  }
  //--------------------------------------------------//
  //--------------------------------------------------//
  const onDragEnd = (e: any) => {
    if (!e.destination) return;
    let tempTempData = [...temp_data];
    let tempData = [...data];
    let [source_data] = tempData.splice(e.source.index, 1);
    tempData.splice(e.destination.index, 0, source_data);
    tempData.map((d, index) => d._sort = tempTempData[0]._sort + index);

    setData(tempData);
    setIsUpdated(true);
  };

  const onUpdate = () => {
    data.map(async (d) => await CollabService.updateContent(d._id, d));
    setIsUpdated(false);
  }

  const onCancel = () => {
    setData(stableSort(JSON.parse(JSON.stringify(props.data)), orderType, '_sort'));
    setIsUpdated(false);
  }
  //--------------------------------------------------//
  //--------------------------------------------------//
  return (
    <TableContainer component={Paper} elevation={0}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Table size="small" sx={{ minWidth: maxWidth }} aria-label="customized table">
          <HTable cells={headerCells} sortHandler={sortHandler} isCanSort={props.isCanSort} />
          <Droppable droppableId="droppable-1">
            {(provider) => (
              <TableBody ref={provider.innerRef}
                {...provider.droppableProps}
              >
                {data && data.map((row: ICollab, index: number) => (
                  <Draggable
                    key={row._id}
                    draggableId={row._id}
                    index={index}
                  >
                    {(provider) => (
                      <TableRow key={index} {...provider.draggableProps} {...provider.dragHandleProps} ref={provider.innerRef}>
                        <TableCell align="left">{index + 1}</TableCell>
                        <TableCell align="left">
                          <Paper className='flex-c-m' elevation={1}>
                            <img src={`${env.APP_API_HOST}/${row.icon}`} alt="img" width={50} height={50} />
                          </Paper>
                        </TableCell>
                        <TableCell align="left">{row.name}</TableCell>
                        <TableCell align="center">{row.thumbnail.length}</TableCell>

                        <TableCell align="center">
                          <TypeIC value="sticker" disabled={true} selected={row.sticker} size={50} />
                          <TypeIC value="mug" disabled={true} selected={row.mug} size={50} />
                          <TypeIC value="shirt" disabled={true} selected={row.shirt} size={50} />
                          <TypeIC value="totebag" disabled={true} selected={row.totebag} size={50} />

                        </TableCell>
                        <TableCell align="left">{row.type.toUpperCase()}</TableCell>
                        <TableCell align="left">
                          <Checkbox checked={row.activate} />
                        </TableCell>
                        <TableCell align="center">
                          {row.type.toLowerCase() === 'event' ? row.date_expire : ''}
                        </TableCell>
                        <TableCell align="left" colSpan={2}>
                          {props.isCanView &&
                            <Button variant="contained" size="small" sx={{ px: 3 }} onClick={() => { onClickView(row); }}>
                              <Typography variant="body1">EDIT</Typography>
                            </Button>
                          }
                        </TableCell>
                      </TableRow>
                    )}
                  </Draggable>
                ))}
                {provider.placeholder}
              </TableBody>
            )}
          </Droppable>
        </Table>
      </DragDropContext>
      {isUpdated &&
        <BUpdate bLabel={'RESET SORT'} onCancel={onCancel}
          nLabel={'UPDATE SORT'} onComfirm={onUpdate} />
      }
    </TableContainer >
  );

  //--------------------------------------------------//
  //--------------------------------------------------//
}
