import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import Divider from '@mui/material/Divider';
import { Box, Checkbox, FormControlLabel, Typography, Button } from '@mui/material';
import { primary } from '../../../Styles/Theme';
import { ICollab, DCollab } from '../../../Libs/Models/ICollab.model';
import CollabService from '../../../Libs/Services/Collab.service';
import { getIcon } from '../../../Libs/Constants/size';

import Loader from '../../../Libs/Redux/Actions/Loader.action';

import { BUpdate, HName, Search, TStatus, TypeIC, ThumbnailContainer, TypeEvent, ImageRef } from '../../Common';
import { DThumbnail, IThumbnail } from '../../../Libs/Models/IAvatar.model';

import { ThumbAdd } from './ThumbAdd';
import { useDispatch } from 'react-redux';
import { openError, openComplete } from '../../Common/Dialog';
import { getFileToServer } from '../../../Libs/Constants/odds';

export function CollabDetail() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { id } = useParams();
    const size = getIcon('thumb');

    const [data, setData] = useState<ICollab>(DCollab());
    const [contentD, setContentD] = useState<ICollab>(DCollab());

    const logoRef = React.useRef<ImageRef>(null);
    //const thumbRef = React.useRef<ImageRef>(null);
    const [newThumb, setThumb] = useState<(File | null)[]>([]);
    const [removeThumb, setRemoveThumb] = useState<Number[]>([]);
    //const [isCanActivate, setIsCanActivate] = useState<boolean>(true);

    useEffect(() => {
        if (id) {
            dispatch(Loader.showLoading(true));
            CollabService.getContent(id)
                .then(res => {
                    let _d_ = JSON.parse(JSON.stringify(res)) as ICollab;
                    let sort = _d_.thumbnail.sort((a: IThumbnail, b: IThumbnail) => a._sort - b._sort);
                    setData({ ..._d_, thumbnail: sort });
                    setContentD({ ..._d_, thumbnail: sort });
                    dispatch(Loader.showLoading(false));
                })
                .catch((e) => { openError({ title: `${e.message}` }); });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    /*  useEffect(() => {
         var now = new Date();
         var date_start = new Date(data.date_start)
         var date_expire = new Date(data.date_expire)
         date_start.setHours(0, 0, 0, 0);
         date_expire.setHours(0, 0, 0, 0);
         now.setHours(0, 0, 0, 0);
         if (!(now >= date_start && now <= date_expire)) {
             setData({ ...data, activate: false });
             setIsCanActivate(false);
         } else {
             setIsCanActivate(true);
         }
     }, [data.date_start, data.date_expire]); */
    //--------------------------------------------------//
    //--------------------------------------------------//
    ///// ACTIVATE, TYPE /////
    const onChangeNameHandler = (value: string) => {
        setData((pre) => ({ ...pre, name: value }));
    }
    const onChangeEventHandler = (value: string) => {
        setData((pre) => ({ ...pre, type: value }));
    }
    const onChangeActivate = (e: any) => {
        setData((pre) => ({ ...pre, activate: e.target.checked }));
    }
    //--------------------------------------------------//
    //--------------------------------------------------//
    const onClickHandler = (label: string) => {
        switch (label) {
            case 'sticker': setData((pre) => ({ ...pre, sticker: !data.sticker })); break;
            case 'mug': setData((pre) => ({ ...pre, mug: !data.mug })); break;
            case 'shirt': setData((pre) => ({ ...pre, shirt: !data.shirt })); break;
            case 'totebag': setData((pre) => ({ ...pre, totebag: !data.totebag })); break;
        }
    }

    //--------------------------------------------------//
    //--------------------------------------------------//
    ///// UPDATE THUMBNAIL /////
    const onResetLogo = (index: number = 0) => {
        setData((pre) => ({ ...pre, icon: contentD.icon }));
    }

    const onUpdateLogo = (value: File | null) => {
        setData(pre => ({ ...pre, icon: value ? URL.createObjectURL(value) : "" }));
    }

    ///// UPDATE GRAPHICS /////   
    const onUpdateThumb = (thumbs: IThumbnail[]) => {
        setData((pre) => ({ ...pre, thumbnail: thumbs }));
    }

    //--------------------------------------------------//
    //--------------------------------------------------//
    ///// BUTTON /////
    const onClear = () => {
        //let ids: Number[] = [...removeThumb];
        //data.thumbnail?.map((res) => { ids.push(res.id); });
        var temp = data.thumbnail?.map((res) => res.id)
        let ids: Number[] = [...removeThumb, ...temp];
        setRemoveThumb(ids);
        setData(pre => ({ ...pre, thumbnail: [] }));
        setThumb([]);
        //setIcon([]);
    }

    const onCancel = () => {
        //<<--back
        navigate(-1);
    }

    const onDelete = async () => {
        //<<--back
        await CollabService.deleteContent(data._id)
            .then(() => { openComplete({}); navigate(-1) })
            .catch(e => { openError({ title: `${e.message}` }) });
    }

    const Error = (err: any) => {
        dispatch(Loader.showLoading(false));
        //dispatch(Loader.showError(err.message));
        openError({ title: `${err.message}` })
    }

    const onConfirm = async () => {
        dispatch(Loader.showLoading(true));
        if (data.id === 0) {
            // Create
            await CollabService.create(data)
                .then((res) => updateSV({ ...JSON.parse(JSON.stringify(res)), thumbnail: data.thumbnail }))
                .catch((e) => { Error(e); })
        } else {
            // Update
            if (removeThumb.length > 0) {    /////มี remove thumb/////
                await CollabService.removeThumb(data.id, removeThumb.join(','))
                    .then(async (res) => { updateSV(data); })
                    .catch((e) => { Error(e); })
            } else {
                updateSV(data);
            }
        }
    };

    async function updateSV(_d_: ICollab | undefined) {
        if (_d_ === undefined) return;
        // จัดตำแหน่ง(id)
        var updateData = { ..._d_, type: data.type, activate: data.activate }; //ใส่ค่าที่เปลี่ยน(ได้) แต่ไม่ได้อัพเดตไปใน detailType
        const ids = updateData.thumbnail?.map(item => { return item.id });
        ids?.sort((a, b) => a - b);
        updateData.thumbnail?.forEach((_t_, index) => {
            //_t_.id = ids[index];
            _t_._sort = index;
        });

        const files = await getFileToServer(updateData.icon, updateData.thumbnail);
        await CollabService.updateContentImages(updateData._id, updateData, files.logo, files.urls, files.icons)
            .then((res: ICollab) => { setData(res); })
            .then(() => dispatch(Loader.showLoading(false)))
            .then(() => { openComplete({}); navigate(-1) })
            .catch((e) => { Error(e); })
    }
    //--------------------------------------------------//
    //--------------------------------------------------//
    ///// LOGO /////
    const onAddThumb = (value: File | null, index: number) => {
        const updatedThumb = [...newThumb];
        if (value) {
            if (updatedThumb.length > index) {
                updatedThumb[index] = value;
            } else {
                updatedThumb.push(value);
            }
            setThumb(updatedThumb);
            onUpdateImage_Data(value, index, null);
        } else {
            setThumb(updatedThumb.splice(index, 1));
        }
    }

    // ลบ 1 อัน
    const onDeleteThumb = (id: number) => {
        setRemoveThumb((prev) => ([...prev, id]));
    }

    const onUpdateImage_Data = (value: File | null, index: number, iconImg: File | null) => {
        const thumbs = [...data.thumbnail];
        if (!thumbs[index]) {
            thumbs[index] = DThumbnail();
        }

        thumbs[index].url = value ? URL.createObjectURL(value) : "";
        thumbs[index].icon = iconImg ? URL.createObjectURL(iconImg) : "";
        setData((pre) => ({ ...pre, thumbnail: thumbs }));
    }
    //--------------------------------------------------//
    //--------------------------------------------------//
    return (
        <Box sx={{ p: 2 }}>
            <HName label={""} isCanDelete={data._id !== ""} onDelete={onDelete} />
            <Divider color={primary} />
            <Box id="DETAIL" className="flex-row flex-m" sx={{ pt: 2 }}>
                <Search type="fill" lable="NAME" value={data.name} onChangeHandler={onChangeNameHandler} />
                <TStatus title="ID" msg={data._id} hStyle={{ width: 10 }} msgStyle={{ width: 200 }} />
                <FormControlLabel control={<Checkbox checked={data.activate} />}
                    label="ACTIVATE" onChange={onChangeActivate}/*  disabled={!isCanActivate} */ />
            </Box>

            <TypeEvent value={data.type} onChangeEventHandler={onChangeEventHandler}
                isCanEXP={true} date_start={data.date_start} date_expire={data.date_expire}
                onChangeDateStartHandler={(date) => setData({ ...data, date_start: date })}
                onChangeDateExpairHandler={(date) => { setData({ ...data, date_expire: date }); }} />

            <Box id="PRODUCT" className="flex-row flex-m" sx={{ py: 1 }}>
                <Typography variant='h6' sx={{ pr: 4, mt: -1 }}>PRODUCT</Typography>
                <TypeIC value="sticker" disabled={false} selected={data.sticker} onClickHandler={onClickHandler} />
                <TypeIC value="mug" disabled={false} selected={data.mug} onClickHandler={onClickHandler} />
                <TypeIC value="shirt" disabled={false} selected={data.shirt} onClickHandler={onClickHandler} />
                <TypeIC value="totebag" disabled={false} selected={data.totebag} onClickHandler={onClickHandler} />
            </Box>

            <Box id="LOGO" sx={{ py: 2, display: 'flex' }}>
                <ThumbnailContainer onUpdate={onUpdateLogo} onReset={onResetLogo}
                    label='LOGO' ref={logoRef}
                    MAX_WIDTH={size.w} MAX_HEIGHT={size.h}
                    VW={100} VH={100}
                    src={data.icon} //isCanRemove={logo !== null}
                />
            </Box>

            <Box id="THUMBNAIL" sx={{ py: 2 }}>
                <Typography variant='h6' sx={{ pr: 4, mt: -1 }}>THUMBNAIL</Typography>
                <ThumbAdd key={1} data={data.thumbnail} //ref={thumbRef}
                    onAddThumb={onAddThumb} onUpdateThumb={onUpdateThumb}
                    onDeleteThumb={onDeleteThumb} />
            </Box>

            {(newThumb.length > 0 || data.thumbnail.length > 0) && (
                <Button variant="contained" color="error" onClick={onClear}
                    sx={{ width: 100 }}>
                    CLEAR
                </Button>)}

            <BUpdate disabled={data.name.trim().length < 1}
                onCancel={onCancel} onComfirm={onConfirm}
            />
            <Divider color={primary} />
        </Box>
    )
    //--------------------------------------------------//
    //--------------------------------------------------//
}

//--------------------------------------------------//
//--------------------------------------------------//