import React, { useEffect, useRef, useState } from 'react';
import { ImageRef } from './Thumbnail';
import ImageService from '../../Libs/Services/Image.service';

interface IProps {
    w: number;
    h: number;
    MAX_WIDTH?: number;
    MAX_HEIGHT?: number;
    data: string[];
    ref?: any;
    onUpdateHandler?: (url: string) => void;
}

const MergeImages = React.forwardRef<ImageRef, IProps>((props, ref) => {
    const canvasRef = useRef<HTMLCanvasElement | null>(null);
    const [mergedImageUrl, setMergedImageUrl] = useState<string>('');

    useEffect(() => {
        if (props) {
            const canvas = canvasRef.current;
            if (canvas) {
                const context = canvas.getContext("2d");
                var w = props.MAX_WIDTH ? props.MAX_WIDTH : props.w;
                var h = props.MAX_HEIGHT ? props.MAX_HEIGHT : props.h;
                context?.clearRect(0, 0, w, h);
                canvas.width = w;
                canvas.height = h;
                mergeImagesToBlob(props.data, canvas, 0, w, h);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props]);

    React.useImperativeHandle(ref, () => {
        return {
            getUrl() { return mergedImageUrl; },
            async getImage() { /* return mergedImageUrl; */ }
        }
    });
    //--------------------------------------------------//
    function setImage(canvas: HTMLCanvasElement) {
        canvas.toBlob(blob => {
            if (blob) {
                setMergedImageUrl(URL.createObjectURL(blob))
            }
        })
    }
    async function mergeImagesToBlob(imageUrls: string[], canvas: HTMLCanvasElement, index: number, w: number, h: number) {
        //const canvas = document.getElementById(`myCanvas`) as HTMLCanvasElement;
        const ctx = canvas?.getContext('2d');
        if (index >= imageUrls.length) {
            setImage(canvas);
            return;
        }

        if (imageUrls[index] === undefined || imageUrls[index] === '') {
            mergeImagesToBlob(imageUrls, canvas, index + 1, w, h);
        } else if (imageUrls[index].indexOf('blob:') > -1) {
            const img = new Image();
            img.onload = () => {
                ctx?.drawImage(img, 0, 0, w, h);
                mergeImagesToBlob(imageUrls, canvas, index + 1, w, h);
            };
            img.src = imageUrls[index];
        } else {
            ImageService.getImage(imageUrls[index])
                .then((res) => {
                    const img = new Image();
                    img.onload = () => {
                        ctx?.drawImage(img, 0, 0, w, h);
                        mergeImagesToBlob(imageUrls, canvas, index + 1, w, h);
                    };
                    img.src = res;
                }).catch(err => {
                    console.log(err);
                });
        }
    }
    //--------------------------------------------------//
    //--------------------------------------------------//
    //--------------------------------------------------//
    return (
        <div style={{ width: props?.w, height: props?.h }}>
            <canvas id={'myCanvas'} ref={canvasRef} style={{ display: 'none' }}></canvas>
            {mergedImageUrl &&
                <img src={mergedImageUrl} alt="MergedImage"
                    width={props.w} height={props.h} />
            }
        </div>
    );
});

export default MergeImages;