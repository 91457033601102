import React, { useState, useEffect, useRef, DragEvent, forwardRef, useImperativeHandle } from 'react';
import { Button, Box, Paper } from '@mui/material';
import Typography from '@mui/material/Typography';

import env from '../../Libs/Services/env';
import { ResizeImage, ConvertBlobToFile } from '../../Libs/Extensions/Image.extension';

interface IProps {
    id?: number;
    imgRef?: HTMLImageElement;
    MAX_WIDTH?: number;
    MAX_HEIGHT?: number;
    VW?: number;    //view image(height)
    VH?: number;    //view image(width)
    src?: string;
    label?: string;
    caption?: React.ReactNode;
    disabled?: boolean;
    isCanRemove?: boolean;
    onReset?: () => void;
    onClear?: () => void;
    onUpdate?: (value: File | null) => void;
    onChange?: (value: File | null, icon: File | null, id: number) => void;
}

/*/////////////////////////////////////////////////////
THUMBNAIL

[ Drag and drop an image here, ... ]

File PNG {MAX_WIDTH}x{MAX_HEIGHT} Pixel
/////////////////////////////////////////////////////*/

export interface ImageRef {
    //getUrl: () => string;
    //getImage:() => Promise<File | null>;
    getUrl: () => string;
    getImage: () => any;
    //remove: () => void;
}

export const ThumbnailContainer = forwardRef<ImageRef, IProps>((props, ref) => {
    const thumbRef = useRef<ImageRef>(null);
    const [isUpdated, setIsUpdate] = useState(false);

    useImperativeHandle(ref, () => {
        return {
            getUrl() {
                return thumbRef.current ? thumbRef.current.getUrl() : '';
            },
            async getImage() { return await thumbRef.current?.getImage(); }
        }
    })
    //--------------------------------------------------//
    //--------------------------------------------------//
    const onUpdate = async (value: File | null) => {
        setIsUpdate(true);
        props.onUpdate && props.onUpdate(value);
    }

    const onRemove = () => {
        //thumbRef.current?.remove();
        setIsUpdate(false);
        props.onReset?.();
    }

    /* const onClear = () => {
        thumbRef.current?.remove();
        setIsUpdate(false);
        props.onClear?.();
    } */
    //--------------------------------------------------//
    //--------------------------------------------------//
    return (
        <div>
            <Typography variant="h6" >{props.label ? props.label : 'THUMBNAIL'}</Typography>
            <ThumbnailView ref={thumbRef}
                id={props.id ? props.id : 0}
                {...props}
                onUpdate={onUpdate}
                onReset={props.onReset}
                src={props.src && `${props.src}`}
            />
            <Typography color="text.disabled" sx={{ fontStyle: 'italic' }}>
                {`File PNG or JPEG ${props.MAX_WIDTH} x ${props.MAX_HEIGHT} Pixel`}
            </Typography>
            {props?.isCanRemove || isUpdated ?
                <Box sx={{ py: 2 }}>
                    <Button variant="contained" color="error" onClick={onRemove}>
                        REMOVE
                    </Button>
                </Box>
                : <Box sx={{ py: 2 }}></Box>
            }
        </div>
    );
});

//--------------------------------------------------//
//--------------------------------------------------//
//--------------------------------------------------//
//--------------------------------------------------//
//--------------------------------------------------//
export const ThumbnailView = forwardRef<ImageRef, IProps>((props, ref) => {
    const [previewImage, setPreviewImage] = useState<string>("");
    const thumbRef = useRef<HTMLImageElement>(null);

    useEffect(() => {
        setPreviewImage(props.src ? props.src : "");
    }, [props]);

    useImperativeHandle(ref, () => {
        return {
            getUrl() {
                return thumbRef.current ? thumbRef.current.src : '';
            },
            async getImage() {
                if (thumbRef.current) {
                    return ConvertBlobToFile(thumbRef.current?.src, 'image.png')
                }
                return null;
            }
        }
    })
    //--------------------------------------------------//
    //--------------------------------------------------//
    const handleDrop = async (event: DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        if (props.disabled && props.disabled) {
            return;
        }
        const file = event.dataTransfer.files?.[0];
        if (file) {
            ////// resize to thumbnail //////
            ResizeImage(file, props.MAX_WIDTH, props.MAX_HEIGHT,
                (resizedImage) => {
                    props.onUpdate?.(resizedImage);
                });
        }
    };

    const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
        event.preventDefault();
    };

    //--------------------------------------------------//
    //--------------------------------------------------//
    return (
        <Box className='flex-c-m'
            my={1} sx={{ borderWidth: 2, p: 1 }}
            border="dotted"
            borderRadius={1}
            borderColor="primary.main"
            onDrop={handleDrop}
            onDragOver={handleDragOver}
        >
            {previewImage !== "" /* || icon */ ?
                <Paper className='flex-c-m'>
                    <img alt="Thumbnail" ref={thumbRef}
                        style={{ maxWidth: props.VW ? props.VW : '100%', height: 'auto' }}
                        src={previewImage.indexOf('blob') > -1 ?
                            previewImage : `${env.APP_API_HOST}/${previewImage}`
                        }
                    />
                </Paper>
                : props.caption ? props.caption :
                    <div className='flex-c' style={{ width: 100, height: 100, alignItems: 'center' }}>
                        <label htmlFor={`input-${props.id}`}>
                            +
                        </label>
                    </div>
            }

            {/* <input type="file"
                    id={`input-${props.id}`}
                    accept="image/png, image/jpeg"
                    onChange={handleThumbnailChange}
                    style={{ display: 'none' }}
                    //disabled={props.disabled}
                    disabled={true}
                /> */}
        </Box>
    )
    //--------------------------------------------------//
    //--------------------------------------------------//
});

//--------------------------------------------------//
//--------------------------------------------------//