import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';

import React from 'react';
import dayjs from 'dayjs';

interface IProps {
    onUpdateHandler?: (date: string) => void;
    disabled?: boolean;
    label?: string;
    date?: string | undefined;
}

export function TimePickers(props: IProps) {
    const [value, setValue] = React.useState<dayjs.Dayjs | string | null>(dayjs(dayjs()).format('YYYY-MM-DD'));

    React.useEffect(() => {
        if (props.date !== "") {
            setValue(dayjs(props.date).format('YYYY-MM-DD'));
        }
    }, [props])

    const show = (e: any) => {
        e.preventDefault();
        setValue(e.target.value);
        props.onUpdateHandler?.(e.target.value);
    };

    return (
        <Stack component="form" noValidate direction="row" spacing={2}>
            <TextField
                id="start"
                type="date"
                size="small"
                label={props.label}
                disabled={props?.disabled}
                defaultValue={value}
                value={value}
                sx={{ width: 150 }}
                InputLabelProps={{
                    shrink: true,
                }}
                onChange={(newValue) => show(newValue)}
            />
        </Stack>
    );
}