import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Divider from '@mui/material/Divider';
import { Box, Checkbox, FormControlLabel, Stack, Typography } from '@mui/material';
import { primary } from '../../../../Styles/Theme';
import { getSize, PART } from '../../../../Libs/Constants/size';
import { IColor, DColor, CompareColors } from '../../../../Libs/Models/IColor.model';
import ColorService from '../../../../Libs/Services/Color.service';
import Loader from '../../../../Libs/Redux/Actions/Loader.action';

import { BUpdate, HName, Search, TStatus, ThumbnailContainer, TypeEvent } from '../../../Common';
import { ImageRef } from '../../../Common/Thumbnail';
import { openError, openComplete } from '../../../Common/Dialog';

export function ColorDetail() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { id } = useParams();
    const [data, setData] = useState<IColor>(DColor());
    const [contentD, setContentD] = useState<IColor>(DColor());
    const size = getSize('color');

    const [isUpdated, setUpdated] = useState(false);
    //const [isCheckAll, setIsCheckAll] = useState(false);
    const thumbRef = React.useRef<ImageRef>(null);

    useEffect(() => {
        if (id) {
            ColorService.getContent(id)
                .then(res => {
                    setData(JSON.parse(JSON.stringify(res)));
                    setContentD(JSON.parse(JSON.stringify(res)));
                })
            //.catch(e => { openError({ title: `${e.message}` }) });
        }
    }, [id])
    useEffect(() => {
        setUpdated(!CompareColors(data, contentD));

        //const commonElements = data.part.split(',').filter((element) => PART.includes(element));
        //setIsCheckAll(commonElements.length >= PART.length);
    }, [data, contentD]);

    //--------------------------------------------------//
    //--------------------------------------------------//
    const onChangeNameHandler = (value: string) => {
        setData((pre) => ({ ...pre, name: value }));
    }

    const onChangeEventHandler = (value: string) => {
        setData((pre) => ({ ...pre, type: value }));
    }

    const onChangeActivate = (e: any) => {
        setData((pre) => ({ ...pre, activate: e.target.checked }));
    }

    //--------------------------------------------------//
    //--------------------------------------------------//
    const onResetThumb = () => {
        setData((pre) => ({ ...pre, url: contentD.url }));
    }
    const onUpdateThumb = (value: File | null) => {
        setData((pre) => ({ ...pre, url: value ? URL.createObjectURL(value) : "" }));
    }
    const onDelete = async () => {
        //<<--back
        await ColorService.deleteContent(data._id);
        navigate(-1);
    }
    //--------------------------------------------------//
    //--------------------------------------------------//
    const onConfirm = async () => {
        dispatch(Loader.showLoading(true));
        var img = await thumbRef.current?.getImage();
        await ColorService.create(data, img)
            .then((res: IColor) => { setData(res); })
            .then(() => { dispatch(Loader.showLoading(false)); })
            .then(() => { openComplete({}); navigate(-1) })
            .catch(e => { Error(e); });
    };

    const onCancel = () => {
        //<<--back
        navigate(-1);
    }

    const Error = (err: any) => {
        dispatch(Loader.showLoading(false));
        //dispatch(Loader.showError(err.message));
        openError({ title: `${err.message}` });
    }

    const onCheckbox = (e: any, label: string) => {
        var _part_ = data.part.split(',');
        if (e.target.checked) { //Add
            _part_.push(label);
        } else {  //Remove
            _part_ = _part_.filter(r => r !== label);
        }
        _part_ = _part_.filter(r => r !== "");
        setData({ ...data, part: _part_.join(',').trim() });
    }

    /* const onCheckboxAll = (e: any,) => {
        if (e.target.checked) { //Add
            setData({ ...data, part: PART.join(',').toLowerCase().trim() });
            setIsCheckAll(true);
        } else {
            setData({ ...data, part: '' });
            setIsCheckAll(false);
        }
    } */
    //--------------------------------------------------//
    //--------------------------------------------------//
    return (
        <Box sx={{ p: 2 }}>
            <HName label={"COLOR"} isCanDelete={data._id !== ""} onDelete={onDelete} />
            <Divider color={primary} />
            <Box id="DETAIL" className="flex-row flex-m" sx={{ pt: 2 }}>
                <Search type="fill" lable="NAME" value={data.name} onChangeHandler={onChangeNameHandler} />
                <TStatus title="ID" msg={data._id} hStyle={{ width: 10 }} msgStyle={{ width: 200 }} />
                <FormControlLabel label="ACTIVATE"
                    control={<Checkbox checked={data.activate} />} onChange={onChangeActivate} />
            </Box>
            <TypeEvent value={data.type} onChangeEventHandler={onChangeEventHandler}
                isCanEXP={true} date_start={data.date_start} date_expire={data.date_expire}
                onChangeDateStartHandler={(date) => setData({ ...data, date_start: date })}
                onChangeDateExpairHandler={(date) => { setData({ ...data, date_expire: date }); }} />

            <Box sx={{ py: 2 }}>
                <Stack id="Face" direction="row" sx={{ alignItems: 'center' }}>
                    <Typography variant='h6' sx={{ pr: 2 }}>USE WITH</Typography>
                    <Part label="STICKER" value="PD_STICKER" checked={data.part} onCheckbox={onCheckbox} />
                    <Part label="MUG" value="PD_MUG" checked={data.part} onCheckbox={onCheckbox} />
                    <Part label="SHIRT" value="PD_SHIRT" checked={data.part} onCheckbox={onCheckbox} />
                    <Part label="TOTEBAG" value="PD_TOTEBAG" checked={data.part} onCheckbox={onCheckbox} />
                </Stack>
                <Stack id="Assesories" component="form" direction="row" spacing={0} sx={{ pl: 9.5 }}>
                    {
                        PART.map((part: any) => (
                            <Part label={part} value={part} checked={data.part} onCheckbox={onCheckbox} />

                        ))}
                </Stack>
            </Box>


            <Box id="THUMBNAIL" sx={{ py: 2, display: 'flex' }}>
                <ThumbnailContainer MAX_WIDTH={size.w} MAX_HEIGHT={size.h}
                    onUpdate={onUpdateThumb} onReset={onResetThumb}
                    src={data.url}
                    ref={thumbRef}
                />
            </Box>
            <BUpdate disabled={!isUpdated || data.name.trim().length < 1
                || (thumbRef.current?.getImage() !== null && data.url.length < 1)}
                onCancel={onCancel} onComfirm={onConfirm}
            />
            <Divider color={primary} />
        </Box>
    )
    //--------------------------------------------------//
    //--------------------------------------------------//
}

const Part = ({ label, value, checked, onCheckbox }: any) => {
    return (
        <FormControlLabel control={<Checkbox defaultChecked />}
            sx={{ minWidth: 100 }}
            checked={checked.indexOf(value) > -1}
            label={label.toUpperCase()} onChange={(e) => onCheckbox(e, value)} />
    )
}


/* const TypePart = ({ data, checked, onCheckbox }: any) => {
    return (
        <Stack id="Assesories" component="form" direction="row" spacing={1} sx={{}}>{
            data.map((part: any) => (
                <FormControlLabel control={<Checkbox defaultChecked />}
                    sx={{ minWidth: 110 }}
                    checked={checked.indexOf(part) > -1}
                    label={part.toUpperCase()} onChange={(e) => onCheckbox(e, part)} />
            ))
        }
        </Stack>
    )
} */