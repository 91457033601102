import { HttpClient } from './httpClient';

import env from "./env";

export interface IImageService {
    getImage: (url: string) => Promise<any>;

}

class ImageService extends HttpClient implements IImageService {

    public async getImage(link: string): Promise<any> {
        try {
            const url = `${env.APP_API_HOST}/files/image?url=${link}`;
            const response = await this.get(url, { responseType: 'arraybuffer' });
            if (!response.status) {   //ERR_CONNECTION_REFUSED
                throw new Error("Connection failed Please try again.");
            }
            if (!response.data) {
                throw new Error("ไม่พบข้อมูล");
            }
            const data: any = response.data;
            if (data?.status === "error") {
                throw new Error(data.message);
            }
            const imageBlob = new Blob([data], { type: 'image/png' });
            const imageUrl = URL.createObjectURL(imageBlob);
            return imageUrl;
        } catch (e) {
            throw e;
        }
    }
}

const service = new ImageService();
export default service;