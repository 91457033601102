import React, { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import {
  Button,
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography
} from "@mui/material";

import HTable, { IHeadTabel } from '../../Layout/HTable';
import { OrderType, stableSort } from '../../../Libs/Extensions/Number.extension';
import { IProduct } from '../../../Libs/Models/IProduct.model';
import { BUpdate, TypeIC } from '../../Common';
import env from '../../../Libs/Services/env';
import ColorService from '../../../Libs/Services/Color.service';
import { IColor } from '../../../Libs/Models/IColor.model';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import ProductService from '../../../Libs/Services/Product.service';

const headerCells: IHeadTabel[] = [
  { id: "_id", label: "ID", align: "left", sort: true, width: 110 },
  { id: "name", label: "NAME", align: "left", sort: true, width: 150 },
  { id: "type", label: "TYPE", align: "left", sort: true, width: 50 },
  { id: "size", label: "SIZE", align: "left", sort: true, width: 80 },
  { id: null, label: "", align: "left", sort: true, width: 50 },
  { id: 'color', label: "COLOR", align: "left", sort: true, width: 100 },
  { id: "quantity", label: "QUANTITY", align: "center", sort: true, width: 80 },
  { id: "price", label: "PRICE", align: "center", sort: true, width: 80 },
  { id: null, label: "STOCK", align: "center", sort: true, width: 150 },
  { id: "activate", label: "ACTIVATE", align: "center", sort: true, width: 50 },
  { id: null, label: "", align: "left", sort: false },
];

const maxWidth = 1200

interface IProps {
  data: IProduct[];
  isCanSort: boolean;
  isCanView: boolean;
}

export const TableList = (props: IProps) => {
  const navigate = useNavigate();
  //const [orderBy, setOrderBy] = useState("index");
  const [orderType, setOrderType] = useState(OrderType.Asc);

  const [color, setColor] = useState<IColor[]>();
  const [temp_data, setTempData] = useState<IProduct[]>([...props.data]);
  const [data, setData] = useState<IProduct[]>([...props.data]);
  const [isUpdated, setIsUpdated] = useState<boolean>(false);

  useEffect(() => {
    ColorService.getContentAll().then(res => {
      setColor(res);
    })
  }, [])

  useEffect(() => {
    let tempData1 = stableSort(JSON.parse(JSON.stringify(props.data)), orderType, '_sort');
    let tempData2 = stableSort(JSON.parse(JSON.stringify(props.data)), orderType, '_sort');
    setTempData(tempData1);
    setData(tempData2);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data])

  //--------------------------------------------------//
  //--------------------------------------------------//

  const onClickView = (value: IProduct) => {
    //dispatch(Action.getProductDetail(value));
    navigate(`/productlist/` + value._id);
  };

  const sortHandler = (id: keyof IProduct) => {
    if (orderType === OrderType.Asc) {
      setOrderType(OrderType.Desc);
    } else {
      setOrderType(OrderType.Asc);
    }
    //setOrderBy(id);
  }

  const getColorImg = (c: String): string => {
    var res = color?.find(e => e._id === c);
    return res ? res.url : "";
  }

  const getColor = (c: String): string => {
    var res = color?.find(e => e._id === c);
    return res ? res.name : "";
  }

  //--------------------------------------------------//
  //--------------------------------------------------//
  const onDragEnd = (e: any) => {
    if (!e.destination) return;
    let tempTempData = [...temp_data];
    let tempData = [...data];
    let [source_data] = tempData.splice(e.source.index, 1);
    tempData.splice(e.destination.index, 0, source_data);
    tempData.map((d, index) => d._sort = tempTempData[0]._sort + index);

    setData(tempData);
    setIsUpdated(true);
  };

  const onUpdate = () => {
    data.map(async (d) => await ProductService.updateContent(d._id, d));
    setIsUpdated(false);
  }

  const onCancel = () => {
    setData(stableSort(JSON.parse(JSON.stringify(props.data)), orderType, '_sort'));
    setIsUpdated(false);
  }

  //--------------------------------------------------//
  //--------------------------------------------------//
  return (
    <TableContainer component={Paper} elevation={0}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Table size="small" sx={{ minWidth: maxWidth }} aria-label="customized table">
          <HTable cells={headerCells} sortHandler={sortHandler} isCanSort={props.isCanSort} />
          <Droppable droppableId="droppable-1">
            {(provider) => (
              <TableBody
                className="text-capitalize"
                ref={provider.innerRef}
                {...provider.droppableProps}
              >
                {data?.map((row: IProduct, index: number) => (
                  <Draggable
                    key={row._id}
                    draggableId={row._id}
                    index={index}
                  >
                    {(provider) => (
                      <TableRow key={row.id} {...provider.draggableProps} {...provider.dragHandleProps} ref={provider.innerRef}>
                        <TableCell align="left">{row._id}</TableCell>
                        <TableCell align="left">{row.name}</TableCell>
                        <TableCell align="center" >
                          <TypeIC value={row.type} disabled={true} selected={true} size={40} />
                        </TableCell>
                        <TableCell align="center">{row.size}</TableCell>
                        <TableCell align="center">
                          <Paper className='flex-c-m' elevation={1} sx={{ width: 40 }} >
                            <img src={`${env.APP_API_HOST}/${getColorImg(row.color)}`} alt="Color"
                              style={{ width: 40, height: 'auto' }}
                            />
                          </Paper>
                        </TableCell>
                        <TableCell align="left">{getColor(row.color)}</TableCell>
                        <TableCell align="center">{row.quantity}</TableCell>
                        <TableCell align="center">{row.price}</TableCell>
                        {row.quantity > 0 ?
                          <TableCell align="center">IN STOCK</TableCell> :
                          <TableCell align="center"
                            style={{ fontFamily: 'THSarabun', fontWeight: "900", color: '#E14745', fontSize: '1.25rem' }}>
                            OUT OF STOCK
                          </TableCell>
                        }
                        <TableCell align="left">
                          <Checkbox checked={row.activate} />
                        </TableCell>
                        <TableCell align="left" colSpan={2}>
                          {props.isCanView &&
                            <Button variant="contained" size="small" sx={{ px: 3 }} onClick={() => { onClickView(row); }}>
                              <Typography variant="body1">EDIT</Typography>
                            </Button>
                          }
                        </TableCell>
                      </TableRow>
                    )}
                  </Draggable>
                ))}
                {provider.placeholder}
              </TableBody>
            )}
          </Droppable>
        </Table>
      </DragDropContext>
      {isUpdated &&
        <BUpdate bLabel={'RESET SORT'} onCancel={onCancel}
          nLabel={'UPDATE SORT'} onComfirm={onUpdate} />
      }
    </TableContainer>
  );

  //--------------------------------------------------//
  //--------------------------------------------------//
}