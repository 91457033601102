//import { PART } from '../Constants/size';
export interface IColor {
    id: number;
    _id: string;    //cl_id
    _sort: number;  //cl_sort
    part: string;   //เก็บส่วนที่สามารถเอาไปใช้ได้ เช่น 'skin,eye,shirt,pant,background' ... default ="" คือใช้้ได้ทั้งหมด
    name: string;
    type: string;
    url: string;
    date_start: string;
    date_expire: string;
    activate: boolean;
}

export const DColor = (): IColor => {
    return {
        id: 0,
        _id: "",
        _sort: 0,
        part: '',//PART.join(','),
        name: "",
        type: "normal",
        url: "",
        date_start: '',
        date_expire: '',
        activate: true
    }
}

export function CompareColors(a: IColor, b: IColor): boolean {
    if (
        a.id !== b.id ||
        a._id !== b._id ||
        a._sort !== b._sort ||
        a.part !== b.part ||
        a.name !== b.name ||
        a.type !== b.type ||
        a.url !== b.url ||
        a.date_start !== b.date_start ||
        a.date_expire !== b.date_expire ||
        a.activate !== b.activate
    ) {
        return false;
    }

    return true;
}