import ActionType from "../@types/ActionType";

class LoaderAction {

    showLoading = (value: boolean) => ({
        type: ActionType.LOADING_CHANGE,
        payload: value
    });

    showError = (value: string) => ({
        type: ActionType.ERROR_SHOW,
        payload: value
    });
    closeError = () => ({
        type: ActionType.ERROR_SHOW
    });
    /* showLoading = () => (dispatch: any) => {
        dispatch({
            type: ActionType.LOADING_CHANGE,
            payload: {
                loading: true
            }
        } as IAction<any>);
    };

    hideLoading = () => (dispatch: any) => {
        dispatch({
            type: ActionType.LOADING_CHANGE,
            payload: {
                loading: false
            }
        } as IAction<any>);
    }; */

}
const actionInstance = new LoaderAction();
export default actionInstance;