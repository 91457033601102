//import html2canvas from 'html2canvas';
export async function ConvertBlobToFile(url: string, name: string = 'image.png') {
    /* if (url.indexOf('blob:') < 0) return null;
    const response = await fetch(url);
    const blob = await response.blob();
    return new File([blob], name, { type: 'image/png' }); */
    if (url.indexOf('blob:') > -1) {
        const response = await fetch(url);
        const blob = await response.blob();
        return new File([blob], name, { type: 'image/png' });
    } else {
        const blob = await ConvertURLToBlob(url, name);
        if (blob) {
            return new File([blob], name, { type: 'image/png' });
        }
    }
    return null;
}

export function cloneBlob(originalBlob: Blob) {
    return new Blob([originalBlob], { type: originalBlob.type });
}
//////////////////////////////////////////////////
//////////////////////////////////////////////////
///// เอาอันนี้ /////
export async function imageUrlToBlob(imageUrl: string) {
    try {
        const response = await fetch(imageUrl, { mode: 'no-cors' });
        // Check if the response status is OK (status code 200)
        if (!response.ok) {
            throw new Error('Failed to fetch image');
        }
        const buffer = await response.arrayBuffer();
        const blob = new Blob([buffer], { type: 'image/png' });
        return blob;
    } catch (error) {
        console.error('Error converting image URL to Blob:', error, imageUrl);
        return null;
    }
}
///// เอาอันนี้ /////
export async function imageUrlToFile(imageUrl: string, fileName: string = 'img.png') {
    try {
        // Fetch the image data from the URL
        const response = await fetch(imageUrl);
        // Check if the response status is OK (status code 200)
        if (!response.ok) {
            throw new Error('Failed to fetch image');
        }
        // Read the response body as a Blob
        const blob = await response.blob();
        const contentType = response.headers.get('content-type') || 'image/png';
        // Create a File from the Blob with the desired file name
        const file = new File([blob], fileName, { type: contentType });
        return file;
    } catch (error) {
        console.error('Error converting image URL to File:', error);
        return null;
    }
}

export function dataURLtoFile(dataURL: string, fileName: string = 'img.png') {
    // Split the Data URL into the "data:" part and the actual base64 data
    const [, base64Data] = dataURL.split(',');

    // Decode the base64 data into binary data
    const binaryData = atob(base64Data);

    // Create a Uint8Array from the binary data
    const byteArray = new Uint8Array(binaryData.length);
    for (let i = 0; i < binaryData.length; i++) {
        byteArray[i] = binaryData.charCodeAt(i);
    }

    // Create a Blob from the Uint8Array
    const blob = new Blob([byteArray], { type: 'image/png' }); // You can specify the desired file type here

    // Create a File from the Blob
    return new File([blob], fileName, { type: 'image/png' }); // You can specify the desired file type here
}

//////////////////////////////////////////////////
//////////////////////////////////////////////////
export async function ConvertURLToBlob(url: string, name: string = 'image.png') {
    try {
        const response = await fetch(url);
        const data = await response.blob();
        return data;
    } catch (error) {
        console.error('Error converting URL to Blob:', error);
        return null;
    }
}

export async function ResizeImage(imageFile: File, w: number | undefined, h: number | undefined, callback: (resizedImage: File) => void) {
    const reader = new FileReader();
    reader.onload = (event: any) => {
        const img = new Image();
        img.onload = () => {
            const canvas = document.createElement('canvas');
            let width = img.width;
            let height = img.height;
            if (w && h) {
                if (width > height) {
                    if (width > w) {
                        height *= w / width;
                        width = w;
                    }
                } else {
                    if (height > h) {
                        width *= h / height;
                        height = h;
                    }
                }
            };

            canvas.width = width;
            canvas.height = height;
            const ctx = canvas.getContext('2d');

            ctx?.drawImage(img, 0, 0, width, height);
            canvas.toBlob((blob) => {
                callback(new File([blob as Blob], imageFile.name, { type: imageFile.type }));
            }, imageFile.type, 1);
        };
        if (typeof event.target.result === 'string') {
            img.src = event.target.result;
        }
    };

    reader.readAsDataURL(imageFile);
};

/* async function fetchImages(url: string) {
    try {
        const response = await fetch(url);
        const blob = await response.blob();
        return URL.createObjectURL(blob);
    } catch (error) {
        console.error(`Error fetching image from ${url}:`, error);
        return null;
    }
} */
/* 
export async function MergeImage(
    imageUrls: string[], w: number, h: number,
    callback: (url: File) => void) {
    const imageElements = await Promise.all(imageUrls.map(async (url) => {
        const imageUrl = await fetchImages(url);
        if (imageUrl) {
            const img = new Image();
            img.src = imageUrl;
            //document.body.appendChild(img); // Append the image to the body or any other DOM element
            return img;
        }
        return null;
    }));

    const canvas = document.createElement('canvas');
    canvas.width = w * 2; canvas.height = h * 2;
    const ctx = canvas.getContext('2d');

    console.log(imageElements.length);
    console.log(canvas.width, canvas.height);
    imageElements.forEach((image) => {
        console.log(image);

        image && ctx?.drawImage(image, 0, 0, canvas.width, canvas.height);
    });
    canvas.toBlob(async (blob: any) => {
        if (blob) {
            var fileBlob: File = await new File([blob as Blob], 'icon.png', { type: 'image/png' })
            callback(fileBlob);
        }
    });
    //console.log(imageElements);

   const mergedImage = new Image();
    mergedImage.src = canvas.toDataURL('image/png');
    document.body.appendChild(mergedImage);

    // Now, imageElements will contain references to the created image elements
    console.log('Created image elements:', imageElements);
}


export async function MergeImages(imageUrls: string[],
    w: number, h: number,
    callback: (url: File) => void) {

    const canvas = document.createElement('canvas');
    var images = [];
    for (var i = 0; i < imageUrls.length; i++) {
        if (imageUrls[i] === '') continue;
        const img = new Image();
        // eslint-disable-next-line
        await new Promise((resolve) => {
            //img.onerror = () => reject(new Error('Couldn\'t load image'));
            img.onload = resolve;
            img.src = imageUrls[i];
        });
        images.push(img);
    }

    canvas.width = w;
    canvas.height = h;
    const ctx = canvas.getContext('2d');
    images.forEach((image) => {
        image && ctx?.drawImage(image, 0, 0, canvas.width, canvas.height);
    });

    canvas.toBlob(async (blob: any) => {
        if (blob) {
            var fileBlob: File = await new File([blob as Blob], 'icon.png', { type: 'image/png' })
            callback(fileBlob);
        }
    });
    // Export the merged image as a Data URL
    const mergedImageURL = canvas.toDataURL('image/png');
    return mergedImageURL;
}

export async function DrawImage(imageUrls: string, w: number, h: number,
    callback: (url: File) => void) {
    const canvas = document.createElement('canvas');

    const img = new Image();
    await new Promise((resolve) => {
        img.onload = resolve;
        img.src = imageUrls;
    });
    canvas.width = w;
    canvas.height = h;
    const ctx = canvas.getContext('2d');
    img && ctx?.drawImage(img, 0, 0, canvas.width, canvas.height);
    canvas.toBlob(async (blob: any) => {
        if (blob) {
            var fileBlob: File = await new File([blob as Blob], 'icon.png', { type: 'image/png' })
            callback(fileBlob);
        }
    });

} */