import React, { useRef, useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Box, Checkbox, Divider, FormControlLabel } from '@mui/material';
import { primary } from '../../../../Styles/Theme';
import { IAvatar, DAvatar, IThumbnail } from '../../../../Libs/Models/IAvatar.model';
import AvatarService from '../../../../Libs/Services/Avatar.service';
import Loader from '../../../../Libs/Redux/Actions/Loader.action';
import { BUpdate, TypeEvent, TStatus, HName } from '../../../Common';
import { Type1, IAvatarRef } from './detailType1';
import { Type2 } from './detailType2';
import { Type3 } from './detailType3';
import { openError, openComplete } from '../../../Common/Dialog';
import { getFileToServer } from '../../../../Libs/Constants/odds';

export function AvatarDetail() {
    const navigate = useNavigate();
    const { body, id } = useParams();
    const dispatch = useDispatch();
    const ref = useRef<IAvatarRef>(null);

    const [data, setData] = useState<IAvatar>(DAvatar());
    //const [contentD, setContentD] = useState<IAvatar>(DAvatar());

    const [isUpdated, setUpdated] = useState(false);
    const [isActived, setIsActived] = useState(false);
    //const [isCanActivate, setIsCanActivate] = useState<boolean>(true);

    useEffect(() => {
        if (id && id.indexOf('new') < 0) {   //avatar update
            AvatarService.getContent(id)
                .then(res => {
                    const _d_ = JSON.parse(JSON.stringify(res));
                    const sort = _d_.thumbnail.sort((a: IThumbnail, b: IThumbnail) => a._sort - b._sort);
                    setData({ ..._d_, thumbnail: sort });
                })
                .then(() => { setIsActived(true); })
                .catch((e) => {
                    openError({ title: `${e.message}` });
                    setIsActived(true);
                });
        } else { /*new avatar*/ setIsActived(true); }
    }, [id])

    useEffect(() => {
        onUpdateHandler(data);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    /* useEffect(() => {
        var now = new Date();
        var date_start = new Date(data.date_start)
        var date_expire = new Date(data.date_expire)
        date_start.setHours(0, 0, 0, 0);
        date_expire.setHours(0, 0, 0, 0);
        now.setHours(0, 0, 0, 0);
        if (!(now >= date_start && now <= date_expire)) {
            setData({ ...data, activate: false });
            setIsCanActivate(false);
        } else {
            setIsCanActivate(true);
        }
    }, [data.date_start, data.date_expire]); */

    function onUpdateHandler(_d_: IAvatar) {
        setUpdated(_d_.thumbnail.length > 0)
    }
    //--------------------------------------------------//
    //--------------------------------------------------//    
    ///// ACTIVATE, TYPE /////
    /* const onChangeNameHandler = (value: string) => {
        setData((pre) => ({ ...pre, name: value }));
    } */
    const onChangeEventHandler = (value: string) => {
        setData((pre) => ({ ...pre, type: value }));
    }
    const onChangeActivate = (e: any) => {
        setData((pre) => ({ ...pre, activate: e.target.checked }));
    }
    //--------------------------------------------------//
    //--------------------------------------------------//    
    ///// BUTTON /////
    const onCancel = () => {
        //<<--back
        navigate(-1);
    }

    const onDelete = async () => {
        //<<--back
        await AvatarService.deleteContent(data._id)
            .then(() => { openComplete({}); navigate(-1) })
            .catch(e => { openError({ title: `${e.message}` }) });
    }

    const Error = (err: any) => {
        dispatch(Loader.showLoading(false));
        //dispatch(Loader.showError(err.message));
        openError({ title: `${err.message}` })
    }

    const onConfirm = async () => {
        dispatch(Loader.showLoading(true));
        var _d_ = ref.current?.getData?.();
        const newdata = {
            ..._d_,
            part: body ? body.toLowerCase() : '',
            activate: data.activate,
            type: data.type,
            date_start: data.date_start,
            date_expire: data.date_expire,
        } as IAvatar;
        if (_d_?.id === 0) {
            ////// Create /////
            await AvatarService.create(newdata)
                .then((res) => updateSV({ ...JSON.parse(JSON.stringify(res)), thumbnail: newdata.thumbnail }))
                //.then(() => { openComplete({}); navigate(-1); })
                .catch(err => {
                    Error(err);
                });
        } else {
            ///// Update /////
            const _r_ = ref.current?.getRemoveThumbs?.();
            var ids = _r_?.map(item => { return item.id });
            if (ids && ids.length > 0) {    /////มี remove thumb/////
                await AvatarService.removeThumb(data.id, ids.join(','))
                    .then(async (res) => { updateSV(newdata); })
                    .catch((e) => { Error(e); })
            } else {
                updateSV(newdata);
            }
        }
    }

    async function updateSV(_d_: IAvatar | undefined) {
        if (_d_ === undefined) return;

        // จัดตำแหน่ง(id)
        var updateData = { ..._d_, type: data.type, activate: data.activate }; //ใส่ค่าที่เปลี่ยน(ได้) แต่ไม่ได้อัพเดตไปใน detailType
        const ids = updateData.thumbnail?.map(item => { return item.id });
        ids?.sort((a, b) => a - b);
        updateData.thumbnail?.map((_t_, index) => {
            // _t_.id = ids[index];
            _t_._sort = index;
            return _t_;
        });

        const files = await getFileToServer(updateData.icon, updateData.thumbnail);
        await AvatarService.updateContentImages(updateData._id, updateData, files.logo,
            files.urls.filter(e => e != null),
            files.fgs.filter(e => e != null),
            files.icons.filter(e => e != null))
            .then((res: IAvatar) => { setData(res); ref.current?.resetValue?.(); })
            .then(() => dispatch(Loader.showLoading(false)))
            .then(() => { openComplete({}); navigate(-1) })
            .catch((e) => { Error(e); })
    }
    //--------------------------------------------------//
    //--------------------------------------------------//
    function getType(part: string, data: IAvatar) {
        if (isActived) {
            switch (part) {
                case "eye": case "mouth": case "hair": case "cheek": return (
                    <Type2 ref={ref} part={String(body)} data={data} count={String(body) === 'hair' ? 2 : 1}
                        onUpdateHandler={onUpdateHandler} logoForThumb={true} />
                );
                case "background": return (
                    <Type3 ref={ref} part={String(body)} data={data} count={1}
                        onUpdateHandler={onUpdateHandler} />
                );
                default: return (
                    <Type1 ref={ref} part={String(body)} data={data} count={1}
                        onUpdateHandler={onUpdateHandler} logoForThumb={String(body) !== 'skin'}
                    />
                )
            }
        }
        return <></>
    }
    //--------------------------------------------------//
    //--------------------------------------------------//
    return (
        <Box sx={{ p: 2 }}>
            <HName label={body?.toUpperCase()} isCanDelete={data._id !== ""} onDelete={onDelete} />
            <Divider color={primary} />
            <Box id="DETAIL" className="flex-row flex-m" sx={{ pt: 2 }}>
                <TStatus title="ID" msg={data._id} hStyle={{ width: 10 }} msgStyle={{ width: 200 }} />
                <FormControlLabel control={<Checkbox checked={data.activate} />} label="ACTIVATE" onChange={onChangeActivate} /* disabled={!isCanActivate} */ />
            </Box>
            <TypeEvent value={data.type} onChangeEventHandler={onChangeEventHandler}
                isCanEXP={true} date_start={data.date_start} date_expire={data.date_expire}
                onChangeDateStartHandler={(date) => setData({ ...data, date_start: date })}
                onChangeDateExpairHandler={(date) => { setData({ ...data, date_expire: date }); }}
            />

            {/* GRAPHIC */}
            {getType(String(body), data)}

            <BUpdate disabled={!isUpdated}
                onCancel={onCancel} onComfirm={onConfirm}
            />
            <Divider color={primary} />
        </Box>
    )
    //--------------------------------------------------//
    //--------------------------------------------------//
}
