import { ConvertBlobToFile } from "../Extensions/Image.extension";
import { IThumbnail } from '../Models/IAvatar.model';

export async function getFileToServer(icon: string, thumbnail: IThumbnail[]) {
    const logo = await getFile(icon);
    const urls = await getFiles(thumbnail.map(r => r.url), 'url')
    const fgs = await getFiles(thumbnail.map(r => r.fg), 'fg')
    const icons = await getFiles(thumbnail.map(r => r.icon), 'icon');
    return { logo: logo, urls: urls, fgs: fgs, icons: icons };
}

export async function getFiles(urls: string[], key: string) {
    let imageFiles = [];
    for (var i = 0; i < urls.length; i++) {
        let file = null;

        if (urls[i]?.indexOf('blob:') > -1) {
            file = await ConvertBlobToFile(urls[i], `${key}${i}.png`);
        } /* else {
            if (urls[i] !== "" && urls[i] !== undefined) {
                //const imageUrl = `${env.APP_API_HOST}/${urls[i]}`;
                //file = await imageUrlToBlob(imageUrl);
            }
        } */
        imageFiles[i] = file;
    }
    return imageFiles;
}

export async function getFile(url: string) {
    let file = null;
    if (url?.indexOf('blob:') > -1) {
        file = await ConvertBlobToFile(url, 'logo.png');
    } /* else {
        if (url !== "" && url !== undefined) {
            //const imageUrl = `${env.APP_API_HOST}/${url}`;
            // file = await imageUrlToBlob(imageUrl);
        }
    } */
    return file;
}